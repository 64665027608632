/* eslint-disable no-console */
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import reactotronSaga from 'reactotron-redux-saga';

if (process.env.NODE_ENV === 'development') {
  const tron = Reactotron.configure({ host: '192.168.0.106' })
    .use(reactotronRedux())
    .use(reactotronSaga())
    .connect();

  tron.clear();
  console.tron = tron;

  console.logs = {
    error: (...msgs) => {
      console.log('Erro:', msgs);
    },
    warn: (...msgs) => {
      console.log('Warning:', msgs);
    },
    info: (...msgs) => {
      console.log('Info:', msgs);
    },
  };
} else {
  const tron = {};
  console.tron = tron;
  console.tron.log = () => {};
  console.logs = {
    error: () => {},
    warn: () => {},
    info: () => {},
  };
}
