import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class UsuarioService extends BaseService {
  constructor() {
    super(`${ConfigApi.usuarioURL}`);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.email = '';
    this.idColaboradorVinculo = undefined;
    this.idColaboradorPerfil = undefined;
    this.limit = 11;
    this.page = 0;
  }
}
export default new UsuarioService();
