import styled from 'styled-components';
import background from '../../../assets/images/background-login.png';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-size: contain;
  background: url(${background});
  /* background: #ffff; */
  /* linear-gradient(-90deg, #f0f0f5, #e6ffff); */
  display: flex;
  justify-content: center;
  align-items: center;
`;
