import styled, { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  form {
    width: 100%;
  }

  body {
    --webkit-font-smoothing: antialiased;
  }

  body, input, button, label {
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  th {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  td {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .container-page {
    padding: 10px !important;
    margin-left: -5px !important;
    margin-right: -5px !important;
    padding-bottom: 0px !important;
    /* border-style: solid;
    border: 1px solid #969595;
    border-radius: 4px; */
  }

  .datatable-doc-demo .p-paginator .p-paginator-current {
  margin-left: auto;
}

  .blink-animated {
    animation: blink-animation 2s steps(5, start) infinite;
    -webkit-animation: blink-animation 2s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .botao-pequeno {
    width: 26px !important;
    height: 26px !important;
  }

  .botao-pequeno-largo {
    width: 40px !important;
    height: 26px !important;
  }

  .label-button {
    display: block;
  }

  /* Show priority 3 at 559px (40em x 16px) */
  @media (max-width: 35.95em) {
    .label-button {
      visibility: hidden;
      height: 0px;
    }
  }

  /* formatacao para colunas da grid */
  .grid-col-id {
    width: 58px;
    text-align: left;
    font-size: 13px;
  }

  .grid-col-id-g {
    width: 68px;
    text-align: left;
    font-size: 13px;
  }

  .grid-col-data {
    width: 80px;
    text-align: left;
    font-size: 13px;
  }
  .grid-col-hora {
    width: 63px;
    text-align: left;
    font-size: 13px;
  }

  .grid-col-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .grid-col-id-g {
    width: 90px;
    text-align: left;
    font-size: 13px;
  }

  .unresize {
    resize: none;
  }

  .grid-col-curr {
    width: 120px;
    text-align: end;
    font-size: 13px;
  }

  .grid-col-val {
    width: 90px;
    text-align: end;
    font-size: 13px;
  }


  .grid-col-tel {
    width: 120px;
    font-size: 13px;
  }

  .grid-col {
    font-size: 13px;
  }

  .grid-col-center {
    text-align: center;
  }

  .gid-col-acoes-35 {
    width: 35px;
    text-align: left;
    font-size: 13px;
  }

  /* div flex-grid */
  .p-margin-padding-0 {
    padding: 0 !important;
    margin: 0 !important;
  }

  .p-margin-0 {
    /* padding: 0 !important; */
    margin: 0 !important;
  }

  /*configurações do block-ui*/

  .loading-indicator {
    text-align: center;
  }
  .block-ui-overlay {
  animation: 1s fadein 0.5s both;
  /* wait 1s, fadein over .5s, apply the animation styles both before and after. */
  }

  @keyframes fadein {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 0.5;
    }
  }
  /*fim das configurações do block-ui*/

`;

// export const Label = styled.label`
//   font-weight: bold;
// `;

export const ContainerBase = styled.div`
  margin: 0 !important;
  padding: 0 !important;
`;
