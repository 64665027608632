import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Tree } from 'primereact/tree';

import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import { errorHandle, isNumber, validateFields } from '../../util/functions';
import { UsuarioPerfilFuncaoModel, UsuarioPerfilModel } from '../../util/Models';
import { StateScreen } from '../constants';
import UsuarioPerfilService from '../../services/UsuarioPerfilService';
import FuncaoService from '../../services/FuncaoService';
import { ContainerScrool } from './styles';

function UsuarioPerfilCrud(props) {
  UsuarioPerfilCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  UsuarioPerfilCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [usuarioPerfil, setUsuarioPerfil] = useState(new UsuarioPerfilModel());
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [itensSelecionados, setItensSelecionados] = useState({});
  const [itens, setItens] = useState([]);

  // useCallbacks
  const loadFuncoes = useCallback(async _usuarioPerfil => {
    const it = [];
    const itSelected = {};
    const perfilFuncao = _usuarioPerfil?.usuarioPerfilFuncao || [];

    const funcoes = await FuncaoService.findGroup();
    if (Array.isArray(funcoes.items)) {
      for (let i = 0; i < funcoes.items.length; i++) {
        const element = funcoes.items[i];

        const children = [];
        let qtdAtivo = 0;

        for (let y = 0; y < element.funcoes.length; y++) {
          const ch = element.funcoes[y];
          children.push({
            key: ch.id,
            label: ch.descricao,
            data: ch.nome,
          });

          const funcaoDetalhe = perfilFuncao.find(e => {
            return e.idFuncao === ch.id;
          });

          if (funcaoDetalhe?.ativo || false) {
            qtdAtivo++;
          }

          itSelected[ch.id] = {
            checked: funcaoDetalhe?.ativo || false,
            partialChecked: false,
          };
        }

        itSelected[element.nomeAgrupamento] = {
          checked: qtdAtivo === element.funcoes.length,
          partialChecked: qtdAtivo > 0 && qtdAtivo !== element.funcoes.length,
        };

        it.push({
          key: element.nomeAgrupamento,
          label: element.nomeAgrupamento,
          data: element.nomeAgrupamento,
          children,
        });
      }
    }

    setItens(it);
    setItensSelecionados(itSelected);
  }, []);

  const loadRecord = useCallback(
    async _id => {
      try {
        const retorno = await UsuarioPerfilService.findById(_id);

        await loadFuncoes(retorno);

        setUsuarioPerfil(retorno);
        setErrorLoadRecord(false);
      } catch (err) {
        errorHandle(err);
        setErrorLoadRecord(true);
      }
    },
    [loadFuncoes]
  );

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  function prepareRecord(record) {
    if (!Array.isArray(record.usuarioPerfilFuncao)) {
      record.usuarioPerfilFuncao = [];
    }

    const perfilDetalhe = record.usuarioPerfilFuncao;
    // desativa os não existentes na lista de seleção
    perfilDetalhe.forEach(e => {
      const selecionado = Object.keys(itensSelecionados).find(s => s === e.idFuncao);
      if (!selecionado) {
        e.ativo = false;
      }
    });

    if (itensSelecionados) {
      Object.keys(itensSelecionados).forEach(key => {
        if (isNumber(key)) {
          const perfilFuncao = perfilDetalhe.find(e => e.idFuncao === Number(key));
          if (perfilFuncao) {
            perfilFuncao.ativo = itensSelecionados[key].checked;
          } else {
            const usuarioPerfilFuncao = new UsuarioPerfilFuncaoModel();
            usuarioPerfilFuncao.idFuncao = key;
            usuarioPerfilFuncao.idUsuarioPerfil = record.id;
            usuarioPerfilFuncao.idFuncao = Number(key);
            usuarioPerfilFuncao.funcao.id = Number(key);
            usuarioPerfilFuncao.ativo = itensSelecionados[key].checked;

            perfilDetalhe.push(usuarioPerfilFuncao);
          }
        }
      });
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      prepareRecord(usuarioPerfil);
      if (stateScreen === StateScreen.stInsert) {
        retorno = await UsuarioPerfilService.insert(usuarioPerfil);
      } else {
        retorno = await UsuarioPerfilService.update(usuarioPerfil);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new UsuarioPerfilModel();
      loadFuncoes(novo);
      setUsuarioPerfil(novo);
    }
  }, [loadRecord, idSelected, stateScreen, loadFuncoes]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={usuarioPerfil.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setUsuarioPerfil({ ...usuarioPerfil, nome: e.target.value });
            }}
          />
        </div>

        {usuarioPerfil.id !== 1 ? (
          <ContainerScrool className="p-col-12 p-sm-12 p-lg-12 p-fluid">
            <LabelSp>Opções</LabelSp>
            <Tree
              value={itens}
              filter
              selectionMode="checkbox"
              selectionKeys={itensSelecionados}
              onSelectionChange={e => {
                setItensSelecionados(e?.value);
              }}
            />
          </ContainerScrool>
        ) : null}

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={!validateFields(usuarioPerfil, ['nome'])}
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default UsuarioPerfilCrud;
