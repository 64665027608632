import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '../pages/_layouts/Auth';
import DefaultLayout from '../pages/_layouts/Default';
import AuthService from '../services/AuthService';
import ApiInfoService from '../services/ApiInfoService';
import { store } from '../store';
import { logout, loginFornecedorRequest } from '../store/modules/auth/actions';

export default function RouterWrapper({ component: Component, roles, isPrivate, ...rest }) {
  if (!roles) {
    roles = [];
  }
  let signed = false;
  if (rest.path !== '/notFound') {
    // se isPrivate nao tiver definida e roles existir, então define private true
    if (!isPrivate && roles.length > 0) {
      isPrivate = true;
    }

    if (
      rest.path === '/fornecedor/:option' &&
      rest.computedMatch &&
      rest.computedMatch.params
    ) {
      const { option } = rest.computedMatch.params;

      store.dispatch(loginFornecedorRequest(option, '/cotacaoFornecedor'));

      return null;
    }

    signed = AuthService.acessToken().signed;

    if (rest.path === '/login') {
      signed = false;
    }

    // se for diferente de /login e nao tiver logado vai para login
    if (!signed && rest.path !== '/login') {
      // return <Redirect to="/login" />;
      window.location = '/login';
      return null;
    }

    checkVersaoApi(rest.path);

    if (!signed && isPrivate) {
      // return <Redirect to="/login" />;
      window.location = '/login';
      return null;
    }
    if (signed) {
      const usuario = AuthService.getUsuario();
      if (usuario) {
        const { idFornecedorGerencial } = usuario;

        // somente tokens do fornecedor podem acessar a pagina
        if (rest.path === '/cotacaoFornecedor' && !idFornecedorGerencial) {
          return <Redirect to="/home" />;
        }
        if (idFornecedorGerencial && rest.path !== '/cotacaoFornecedor') {
          return <Redirect to="/cotacaoFornecedor" />;
        }
      }
    }

    if (signed && !isPrivate) {
      return <Redirect to="/home" />;
    }

    if (roles.length > 0 && !AuthService.checkRoles(roles)) {
      store.dispatch(logout());
      return null;
    }
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

async function checkVersaoApi(path) {
  // verificando versao api

  if (!(await ApiInfoService.checkVersaoApi())) {
    if (path !== '/login') {
      store.dispatch(logout());
      //   setTimeout(() => {
      //     window.location = '/login';
      //     window.location.reload();
      //   }, 500);
    }
  }
}

RouterWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};

RouterWrapper.defaultProps = {
  isPrivate: false,
};
