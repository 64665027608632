import jwt from 'jsonwebtoken';

import { store } from '../store';

import authConfig from '../config/auth';
import { isValidArray } from '../util/functions';

class AuthService {
  acessToken() {
    const { token } = store.getState().auth;
    try {
      const decoded = jwt.verify(token, authConfig.secret);
      return {
        signed: true,
        exp: decoded.exp,
        usuario: decoded.usuario,
        token,
      };
    } catch (error) {
      return {
        signed: false,
        exp: 0,
        token: '',
      };
    }
  }

  getUsuario() {
    const { decodedToken } = store.getState().auth;

    try {
      return decodedToken.usuario;
    } catch (error) {
      return null;
    }
  }

  checkRoles(arrayNames) {
    const { decodedToken } = store.getState().auth;
    if (!decodedToken) {
      return false;
    }
    const { roles } = decodedToken;

    if (!isValidArray(arrayNames)) {
      arrayNames = arrayNames ? [arrayNames] : [];
    }

    if (arrayNames.length === 0) {
      return true;
    }

    let ret = false;

    arrayNames.forEach(e => {
      if (!roles || !Array.isArray(roles)) {
        return false;
      }
      if (roles.includes(e)) {
        ret = true;
        return true;
      }
    });

    return ret;
  }
}

export default new AuthService();
