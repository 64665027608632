import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ButtonSp from '../../components/ButtonSp';
import DropDownLazy from '../../components/DropdownLazy';
import DropdownSp from '../../components/DropdownSp';
import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import AuthService from '../../services/AuthService';
import UsuarioPerfilService from '../../services/UsuarioPerfilService';
import UsuarioService from '../../services/UsuarioService';

import { errorHandle, validateFields } from '../../util/functions';
import { UsuarioModel } from '../../util/Models';
import { StateScreen } from '../constants';
import EmpresaService from '../../services/EmpresaService';

function UsuarioCrud(props) {
  UsuarioCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  UsuarioCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // useMemos
  const usuarioLogado = useMemo(() => AuthService.getUsuario(), []);

  // states
  const [usuario, setUsuario] = useState(new UsuarioModel());
  const [usuarioPerfilSelecionado, setUsuarioPerfilSelecionado] = useState(null);
  const [empresaSelecionada, setEmpresaSelecionada] = useState(null);

  const [senha, setSenha] = useState('');
  const [confirmSenha, setConfirmSenha] = useState('');
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  // useCallbacks
  const loadUsuarioPerfil = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await UsuarioPerfilService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadEmpresa = useCallback(async _razaoSocial => {
    if (_razaoSocial !== undefined) {
      const r = await EmpresaService.findAll({ razaoSocial: _razaoSocial, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.razaoSocial,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await UsuarioService.findById(_id);

      setUsuario(retorno);
      const usuarioPerfil = {
        label: retorno.usuarioPerfil.nome,
        value: retorno.usuarioPerfil.id,
      };

      const empresa = retorno.empresa
        ? {
            label: retorno.empresa.razaoSocial,
            value: retorno.empresa.id,
          }
        : null;
      setUsuarioPerfilSelecionado(usuarioPerfil);
      setEmpresaSelecionada(empresa);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    if (senha || confirmSenha) {
      if (senha !== confirmSenha) {
        toast.warn('Senha e confirmação não conferem.');
        return;
      }

      usuario.senha = senha;
    }

    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await UsuarioService.insert(usuario);
      } else {
        retorno = await UsuarioService.update(usuario);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new UsuarioModel();
      setUsuarioPerfilSelecionado(null);
      setEmpresaSelecionada(null);

      setUsuario(novo);
    }
    setSenha('');
    setConfirmSenha('');
  }, [loadRecord, idSelected, stateScreen]);

  const perfilAdministrador = AuthService.getUsuario().idUsuarioPerfil === 1;

  // render principal
  return (
    <>
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>E-mail</LabelSp>
          <InputTextSp
            disabled={viewMode()}
            value={usuario.email}
            maxLength={255}
            required
            type="email"
            onChange={e =>
              setUsuario({
                ...usuario,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Nome</LabelSp>
          <InputTextSp
            value={usuario.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setUsuario({ ...usuario, nome: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Perfil</LabelSp>
          <DropDownLazy
            required
            autoLoad
            disabled={viewMode() || !perfilAdministrador}
            placeholder="Selecione"
            onChange={e => {
              const usuarioPerfil = e ? { id: e.value, nome: e.label } : null;
              setUsuario({
                ...usuario,
                usuarioPerfil,
                idUsuarioPerfil: e?.value,
              });
            }}
            value={usuarioPerfilSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadUsuarioPerfil(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Empresa</LabelSp>
          <DropDownLazy
            autoLoad
            disabled={viewMode() || !perfilAdministrador}
            placeholder="Não definida"
            onChange={e => {
              const empresa = e ? { id: e.value, nome: e.label } : null;
              setUsuario({
                ...usuario,
                empresa,
                idEmpresa: e?.value,
              });
            }}
            required={usuario.idUsuarioPerfil !== 1}
            value={empresaSelecionada}
            onFilter={async txtFilter => {
              const retorno = await loadEmpresa(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Inativo?</LabelSp>
          <DropdownSp
            required
            value={usuario.inativo}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
            disabled={viewMode() || !perfilAdministrador}
            filterInputAutoFocus={false}
            onChange={e => {
              setUsuario({ ...usuario, inativo: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Senha</LabelSp>
          <InputTextSp
            type="password"
            name="txtSenha"
            maxLength={16}
            disabled={
              viewMode() ||
              (usuarioLogado.idUsuarioPerfil !== 1 && usuario.id !== usuarioLogado.id)
            }
            required={stateScreen === StateScreen.stInsert || !!confirmSenha}
            value={senha}
            onChange={e => {
              setSenha(e.target.value);
            }}
          />
        </div>

        <div className="p-col-6 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Confirmação</LabelSp>
          <InputTextSp
            type="password"
            name="txtSenhaConfirmacao"
            maxLength={16}
            disabled={
              viewMode() ||
              (usuarioLogado.idUsuarioPerfil !== 1 && usuario.id !== usuarioLogado.id)
            }
            required={stateScreen === StateScreen.stInsert || !!senha}
            value={confirmSenha}
            onChange={e => {
              setConfirmSenha(e.target.value);
            }}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={
                !validateFields(usuario, [
                  'nome',
                  'email',
                  'idUsuarioPerfil',
                  usuario.idUsuarioPerfil !== 1 ? 'idEmpresa' : '',
                ]) ||
                (stateScreen === StateScreen.stInsert && (!senha || !confirmSenha))
              }
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default UsuarioCrud;
