import { store } from '../store';
import api from './api';
import { loading, loadingSuccess } from '../store/modules/global/actions';
import { geraFiltroHttp } from '../util/functions';

export class BaseService {
  url = '';

  constructor(urlService) {
    this.url = urlService;
    this.api = api;
  }

  setLoading(value) {
    if (value) {
      store.dispatch(loading());
    } else {
      // setTimeout(() => store.dispatch(loadingSuccess()), 350);
      store.dispatch(loadingSuccess());
    }
  }

  async findAll(filter) {
    this.setLoading(true);
    try {
      const filterGet = geraFiltroHttp(filter);
      const response = await api.get(`${this.url}${filterGet}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async findById(id) {
    this.setLoading(true);
    try {
      const response = await api.get(`${this.url}/${id}`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async insert(body) {
    delete body.id;
    this.setLoading(true);
    try {
      const response = await api.post(this.url, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async update(body) {
    try {
      const response = await api.put(`${this.url}/${body.id}`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async delete(id) {
    try {
      await api.delete(`${this.url}/${id}`);
    } finally {
      this.setLoading(false);
    }
  }
}
