import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import InputMaskSp from '../../components/InputMaskSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import { errorHandle, validateFields } from '../../util/functions';
import { EmpresaModel } from '../../util/Models';
import { StateScreen } from '../constants';
import EmpresaService from '../../services/EmpresaService';
import DropdownSp from '../../components/DropdownSp';
import CurrencyInput from '../../components/InputCurrencySp';

function EmpresaCrud(props) {
  EmpresaCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  EmpresaCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [empresa, setEmpresa] = useState(new EmpresaModel());
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await EmpresaService.findById(_id);

      setEmpresa(retorno);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await EmpresaService.insert(empresa);
      } else {
        retorno = await EmpresaService.update(empresa);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new EmpresaModel();

      setEmpresa(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
        <LabelSp>Nr Contrato</LabelSp>
        <CurrencyInput
          mask="999999"
          required
          digits={0}
          value={empresa.numeroContrato}
          disabled={viewMode()}
          onChange={e => {
            setEmpresa({ ...empresa, numeroContrato: e.target.value });
          }}
        />
      </div>

      <div className="p-col-6 p-sm-3 p-lg-1 p-fluid">
        <LabelSp>Inativo?</LabelSp>
        <DropdownSp
          required
          value={empresa.inativo}
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
          disabled={viewMode()}
          filterInputAutoFocus={false}
          onChange={e => {
            setEmpresa({ ...empresa, inativo: e.target.value });
          }}
        />
      </div>

      <div className="p-col-6 p-sm-3 p-lg-2 p-fluid">
        <LabelSp>Tipo Pessoa</LabelSp>
        <DropdownSp
          required
          value={empresa.tipoPessoa}
          options={[
            { label: 'Jurídica', value: 'J' },
            { label: 'Física', value: 'F' },
          ]}
          disabled={viewMode()}
          filterInputAutoFocus={false}
          onChange={e => {
            setEmpresa({ ...empresa, tipoPessoa: e.target.value });
          }}
        />
      </div>

      {empresa.tipoPessoa === 'F' && (
        <div className="p-col-6 p-sm-4 p-lg-2 p-fluid">
          <LabelSp>CPF</LabelSp>
          <InputMaskSp
            required
            mask="999.999.999-99"
            value={empresa.cpfCnpj}
            disabled={viewMode()}
            onChange={e => {
              setEmpresa({ ...empresa, cpfCnpj: e.target.value });
            }}
          />
        </div>
      )}

      {empresa.tipoPessoa === 'J' && (
        <div className="p-col-6 p-sm-4 p-lg-2 p-fluid">
          <LabelSp>CNPJ</LabelSp>
          <InputMaskSp
            required
            mask="99.999.999/9999-99"
            value={empresa.cpfCnpj}
            disabled={viewMode()}
            onChange={e => {
              setEmpresa({ ...empresa, cpfCnpj: e.target.value });
            }}
          />
        </div>
      )}

      <div className="p-col-12 p-sm-8 p-lg-5 p-fluid">
        <LabelSp>Razão Social</LabelSp>
        <InputTextSp
          value={empresa.razaoSocial}
          maxLength={100}
          required
          disabled={viewMode()}
          onChange={e => {
            setEmpresa({ ...empresa, razaoSocial: e.target.value });
          }}
        />
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            disabled={!validateFields(empresa, ['razaoSocial', 'cpfCnpj', 'numeroContrato'])}
            showConfirmation
            onClick={handleSave}
          />
        ) : null}
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
}

export default EmpresaCrud;
