/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

// import { Container } from './styles';

export default function LabelSp(props) {
  const { style, ...rest } = props;
  return (
    <div style={{ paddingBottom: 2 }}>
      <label style={{ fontWeight: 'bold', ...style }} {...rest}>
        {props.children}
      </label>
    </div>
  );
}
