import React from 'react';
import { Switch } from 'react-router-dom';

import Home from '../pages/Home';
import Route from './Route';
import Login from '../pages/Login';
import Usuario from '../pages/Usuario';
import CotacaoFornecedor from '../pages/CotacaoFornecedor';
import AcessDenied from '../pages/AcessDenied';

import NotFound from '../pages/NotFound';
import Auditoria from '../pages/Auditoria';
import UsuarioPerfil from '../pages/UsuarioPerfil';
import Empresa from '../pages/Empresa';
import Cotacao from '../pages/Cotacao';
// import Pdf from '../pages/Pdf';

export default function Routes() {
  return (
    <Switch>
      <Route path="/fornecedor/:option" component={Home} />
      <Route path="/" exact component={Login} />
      <Route path="/login" exact component={Login} />
      <Route path="/denied" exact component={AcessDenied} />
      <Route path="/home" component={Home} isPrivate />
      <Route path="/notFound" component={NotFound} />

      <Route path="/cotacaoFornecedor" component={CotacaoFornecedor} isPrivate />

      {/* usuarios  */}
      <Route
        path="/usuarios/:option"
        component={Usuario}
        roles={['USUARIO_VIEW', 'USUARIO_INSERT', 'USUARIO_UPDATE']}
      />
      <Route path="/usuarios" component={Usuario} roles={['USUARIO_VIEW']} />

      {/* empresas  */}
      <Route
        path="/empresas/:option"
        component={Empresa}
        roles={['EMPRESA_VIEW', 'EMPRESA_INSERT', 'EMPRESA_UPDATE']}
      />
      <Route path="/empresas" component={Empresa} roles={['EMPRESA_VIEW']} />

      {/* cotacoes  */}
      <Route path="/cotacoes/:option" component={Cotacao} roles={['COTACAO_VIEW']} />
      <Route path="/cotacoes" component={Cotacao} roles={['COTACAO_VIEW']} />

      {/* Auditorias */}
      <Route path="/auditorias/:option" component={Auditoria} roles={['AUDITORIA_VIEW']} />
      <Route path="/auditorias" component={Auditoria} roles={['AUDITORIA_VIEW']} />

      {/* Usuario perfil */}
      <Route
        path="/usuarioPerfis/:option"
        component={UsuarioPerfil}
        roles={['USUARIO_PERFIL_VIEW', 'USUARIO_PERFIL_INSERT', 'USUARIO_PERFIL_UPDATE']}
      />
      <Route path="/usuarioPerfis" component={UsuarioPerfil} roles={['USUARIO_PERFIL_VIEW']} />

      {/* se não encontrar nenhuma rota, vá para not found */}
      <Route path="/" isPrivate component={NotFound} />
    </Switch>
  );
}
