export function loading() {
  return {
    type: '@global/LOADING',
  };
}

export function loadingSuccess() {
  return {
    type: '@global/LOADING_SUCCESS',
  };
}

export function showFilter() {
  return {
    type: '@global/SHOW_FILTER',
  };
}

export function hideFilter() {
  return {
    type: '@global/HIDE_FILTER',
  };
}

export function expiringToken() {
  return {
    type: '@global/TOKEN_EXPIRING',
  };
}

export function validToken() {
  return {
    type: '@global/TOKEN_VALID',
  };
}
