import React from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Container } from './styles';
import { store } from '../../store';
import { showDialog, hiddenDialog } from '../../store/modules/dialog/actions';

export default function MessageDialog() {
  const { visible, title, body, handleClose } = useSelector(state => {
    return state.dialog.config;
  });

  function hiddenMessage() {
    const { dispatch } = store;
    dispatch(hiddenDialog());
  }

  const footer = (
    <div>
      <Button
        label="Sim"
        icon="pi pi-check"
        onClick={() => {
          hiddenMessage();
          if (handleClose) {
            handleClose(1);
          }
        }}
        className="p-button-success"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        onClick={() => {
          hiddenMessage();
          if (handleClose) {
            handleClose(2);
          }
        }}
        className="p-button-danger"
      />
    </div>
  );
  return (
    <Container>
      <Dialog
        closable={false}
        footer={footer}
        header={title || 'Confirmação'}
        visible={visible}
        style={{ minWidth: '350px', maxWidth: '400px', width: '95%' }}
        modal
        onHide={() => {
          hiddenMessage();
          if (handleClose) {
            handleClose(0);
          }
        }}
      >
        {body || ''}
      </Dialog>
    </Container>
  );
}

export function showMessage(title, header, handleClose) {
  const { dispatch } = store;
  dispatch(showDialog(title, header, handleClose));
}
