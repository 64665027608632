import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;

  th {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  td {
    padding-left: 4px !important;
    padding-right: 4px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    height: 36px !important;
  }

  //configurações gerais para disabled
  .p-disabled,
  .p-component:disabled {
    opacity: 0.95 !important;
    filter: alpha(opacity=95) !important;
  }

  body .p-disabled,
  .p-component.p-button:disabled {
    opacity: 0.5 !important;
    filter: alpha(opacity=50) !important;
  }

  /* Place your extensions here to make migration to a new release easier. */
  div .p-col-12,
  .p-md-12,
  .p-lg-12,
  .p-col-11,
  .p-md-11,
  .p-lg-11,
  .p-col-10,
  .p-md-10,
  .p-lg-10,
  .p-col-10,
  .p-md-10,
  .p-lg-10,
  .p-col-9,
  .p-md-9,
  .p-lg-9,
  .p-col-9,
  .p-md-9,
  .p-lg-9,
  .p-col-8,
  .p-md-8,
  .p-lg-8,
  .p-col-8,
  .p-md-8,
  .p-lg-8,
  .p-col-7,
  .p-md-7,
  .p-lg-7,
  .p-col-7,
  .p-md-7,
  .p-lg-7,
  .p-col-6,
  .p-md-6,
  .p-lg-6,
  .p-col-6,
  .p-md-6,
  .p-lg-6,
  .p-col-5,
  .p-md-5,
  .p-lg-5,
  .p-col-5,
  .p-md-5,
  .p-lg-5,
  .p-col-4,
  .p-md-4,
  .p-lg-4,
  .p-col-4,
  .p-md-4,
  .p-lg-4,
  .p-col-3,
  .p-md-3,
  .p-lg-3,
  .p-col-3,
  .p-md-3,
  .p-lg-3,
  .p-col-2,
  .p-md-2,
  .p-lg-2,
  .p-col-2,
  .p-md-2,
  .p-lg-2,
  .p-col-1,
  .p-md-1,
  .p-lg-1,
  .p-col-1,
  .p-md-1,
  .p-lg-1 {
    padding: 2px;
  }
`;
