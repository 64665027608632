import React from 'react';

import { DataTable } from 'primereact/datatable';
import { ContainerGrid } from './styles';

export default function DataTableSp(props) {
  return (
    <ContainerGrid>
      <DataTable
        style={{ marginBottom: '2px' }}
        paginator
        currentPageReportTemplate={
          props.totalRecords ? 'Mostrando {first} a {last} de um total de {totalRecords}' : ''
        }
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
        {...props}
      >
        {props.children}
      </DataTable>
    </ContainerGrid>
  );
}
