export function showDialog(title, body, handleClose) {
  return {
    type: '@dialog/SHOW',
    config: { visible: true, title, body, handleClose },
  };
}

export function hiddenDialog() {
  return {
    type: '@dialog/HIDDEN',
    config: { visible: false, title: '', body: '', handleClose: null },
  };
}

export function showDialogLogin() {
  return {
    type: '@dialog/SHOW_LOGIN',
    config: { loginVisible: true },
  };
}

export function hiddenDialogLogin() {
  return {
    type: '@dialog/HIDDEN_LOGIN',
    config: { loginVisible: false },
  };
}

export function showLookup(lookupConfig) {
  return {
    type: '@dialog/SHOW_LOOKUP',
    config: { lookupVisible: true, lookupConfig },
  };
}

export function hiddenLookup() {
  return {
    type: '@dialog/HIDDEN_LOOKUP',
    config: { lookupVisible: false },
  };
}
