/* eslint-disable no-restricted-globals */

import {
  format,
  parse,
  addDays,
  differenceInMinutes,
  lastDayOfMonth,
  lastDayOfWeek,
  addMonths,
  intervalToDuration,
} from 'date-fns';

import { toast } from 'react-toastify';
// import { lastDayOfMonth, lastDayOfWeek, addMonths } from 'date-fns/esm';

import queryString from 'query-string';

// eslint-disable-next-line import/no-cycle
import { store } from '../store';
// import { showDialogLogin } from '../store/modules/dialog/actions';
import { StateScreen } from '../pages/constants';
import { logout } from '../store/modules/auth/actions';

export const { format: formatCurr } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function formatFloat(value, digits = 2) {
  const f = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return f.format(value);
}

export function formatDate(date, dateFormat = 'yyyy-MM-dd') {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    if (date.length <= 10) {
      date += 'T00:00:00'; // evitar problema com fuso-horário
    }

    date = new Date(date);
  }

  return format(date, dateFormat);
}

export function addMes(date, qtd) {
  return addMonths(date, qtd);
}

export function strToDate(strDate, dateFormat = 'yyyy-MM-dd') {
  if (isDate(strDate, dateFormat)) {
    return parse(strDate, dateFormat, new Date());
  }
  return null;
}

export function firstDayYear(date) {
  const yean = formatDate(date, 'yyyy');
  return strToDate(`01-01-${yean}`, 'dd-MM-yyyy');
}

export function lastDayYear(date) {
  const year = formatDate(date, 'yyyy');
  return strToDate(`31-12-${year}`, 'dd-MM-yyyy');
}

export function firstDayMonth(date) {
  const monthYear = formatDate(date, 'MM-yyyy');

  return strToDate(`01-${monthYear}`, 'dd-MM-yyyy');
}

export function lastDayMonth(date) {
  return lastDayOfMonth(date);
}

export function lastDayweek(date, SundayFirstDay = true) {
  const lasDay = lastDayOfWeek(date);
  const day = SundayFirstDay ? 1 : 0;
  return addDays(lasDay, day);
}

export function firstDayweek(date, SundayFirstDay = true) {
  const lastDia = lastDayweek(date, SundayFirstDay);
  return addDays(lastDia, -7);
}

export function isDate(strDate, dateFormat = 'yyyy-MM-dd') {
  const retorno = parse(strDate, dateFormat, new Date());
  return !!retorno && retorno.toString() !== 'Invalid Date';
}

export function calculaIdadeIdade(dataAtual, dataNascimento) {
  const dAtual = typeof dataAtual === 'string' ? strToDate(dataAtual) : dataAtual;
  const dNascimento =
    typeof dataNascimento === 'string' ? strToDate(dataNascimento) : dataNascimento;

  if (dAtual && dNascimento) {
    const { years, months, days } = intervalToDuration({
      start: dNascimento,
      end: dAtual,
    });

    return [years || 0, months || 0, days || 0];
  }
  return [];
}

export function calculaIdadeTexto(dataAtual, dataNascimento) {
  if (!dataAtual || !dataNascimento) {
    return 'Não informada';
  }
  const [anos, meses, dias] = calculaIdadeIdade(dataAtual, dataNascimento);

  let retorno = '';
  if (anos > 0) {
    if (anos > 1) {
      retorno += `${anos} anos`;
    } else {
      retorno += `${anos} ano`;
    }
  }

  if (retorno) {
    retorno += ', ';
  }

  if (meses > 1) {
    retorno += `${meses} meses`;
  } else {
    retorno += `${meses} mês`;
  }

  if (retorno) {
    retorno += ' e ';
  }

  if (dias > 1) {
    retorno += `${dias} dias`;
  } else {
    retorno += `${dias} dia`;
  }

  return retorno;
}

export function geraFiltroHttp(filtros, aceitaVazio = true) {
  if (!filtros) return '';
  let retorno = '';

  Object.entries(filtros).forEach(([key, value]) => {
    if (value && typeof value === 'string') {
      if (aceitaVazio && value.trim() === '') {
        value = null;
      }
    }

    // if (value && value instanceof Date) {
    //   value = value.toISOString();
    // }

    if (value) {
      if (retorno === '') {
        retorno += `?${key}=${value}`;
      } else {
        retorno += `&${key}=${value}`;
      }
    }
  });

  return retorno;
}

export function isMobile() {
  return window.innerWidth <= 1024;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function errorHandle(error) {
  let msg = '';
  if (typeof error === 'string') {
    toast.error(error);
  } else if (typeof error === 'object') {
    if (error.tokenExpired) {
      msg = 'Seu acesso expirou.';
      setTimeout(() => {
        store.dispatch(logout());
      }, 3000);
    } else if (error.response) {
      if (error.response.data.error && typeof error.response.data.error === 'string') {
        msg = error.response.data.error;
      } else if (error.response.status === 404) {
        msg = `404 - Recurso não encontrato no servidor.`;
      } else if (error.response.status === 400) {
        msg = '400 - Requisição inválida';
      } else if (error.response.status === 401) {
        msg = '401 - Não autorizado';
      } else if (error.response.status === 405) {
        msg = '405 - Método não permitido';
      } else if (error.response.status === 403) {
        msg = '403 - Você não tem permissão para executar esta ação';
      }
      if (error.response.data.mensagemUsuario && error.response.data.mensagemUsuario !== '') {
        msg = error.response.data.mensagemUsuario;
      }
    } else {
      msg = error.toString();
    }
  } else {
    msg = 'Não foi possível processar ação. Verifique sua conexão e tente novamente.';
  }
  if (msg === '') {
    msg = 'Não foi possível processar ação. Verifique sua conexão e tente novamente.';
  }

  if (error?.response?.data?.mensagemDesenvolvedor) {
    console.logs.error(
      `msg dev: ${error?.response?.data?.mensagemDesenvolvedor}`,
      error?.response?.data.detalhe
    );
  } else {
    console.logs.error(`msg dev: ${msg}`);
  }
  toast.error(msg);
}

export function isNumber(val) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(val);
}

export function getPageParams(params, search) {
  const { option } = params;
  const options = queryString.parse(search);
  const { toBack } = queryString.parse(search);
  const view = options.view !== undefined;
  let stScreen = StateScreen.stSearch;
  let idSelected = 0;

  if (isNumber(option)) {
    if (view) {
      stScreen = StateScreen.stView;
      idSelected = Number(option);
    } else {
      idSelected = Number(option);
      stScreen = StateScreen.stUpdate;
    }
  } else if (option === 'insert') {
    stScreen = StateScreen.stInsert;
  }

  return {
    stateScreen: stScreen,
    idSelected,
    toBack,
  };
}

export function cloneObj(obj) {
  if (obj !== undefined && obj !== null) {
    return JSON.parse(JSON.stringify(obj));
  }
  return null;
}
export function arredondaValor(val, digts = 2) {
  if (typeof val === 'number') {
    const strRetorno = val.toFixed(digts);
    return parseFloat(strRetorno);
  }
  return 0;
}

export function getElementArray(array, localValue, prevNext) {
  let i = -1;
  for (let x = 0; x < array.length; x++) {
    if (array[x].value === localValue.value) {
      i = x;
      break;
    }
  }

  if (i >= 0 && i + prevNext < array.length && i + prevNext >= 0) {
    return array[i + prevNext];
  }
  return null;
}

export function getDiferencaEmMinutos(dateFinal, dateInicial) {
  return differenceInMinutes(dateFinal, dateInicial);
}

export function validateFields(obj, fields) {
  const array = fields && typeof fields.forEach === 'function' ? fields : [fields];

  let ret = false;
  if (obj && typeof obj === 'object') {
    if (array && array.length > 0) {
      ret = true;
      array.forEach(e => {
        if (e !== '') {
          if (obj[e] !== null && obj[e] !== undefined) {
            if (typeof obj[e] === 'string' && obj[e] === '') {
              ret = false;
            } else if (typeof obj[e] === 'number' && obj[e] <= 0) {
              ret = false;
            } else if (typeof obj[e] === 'object' && !obj[e]) {
              ret = false;
            } else if (typeof obj[e] === 'bigint' && obj[e] <= 0) {
              ret = false;
            } else if (typeof obj[e] === 'undefined') {
              ret = false;
            }
          } else {
            ret = false;
          }
        }
      });
    }
  }

  return ret;
}

export function validCPF(strCPF, blankValid = false) {
  if (!strCPF && blankValid) {
    return true;
  }
  strCPF = strCPF.replace(/[^\d]+/g, '');
  let Soma;
  let Resto;
  Soma = 0;
  let i;
  if (!strCPF || strCPF === '00000000000' || strCPF === '') return false;

  for (i = 1; i <= 9; i++) Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

  Soma = 0;
  for (i = 1; i <= 10; i++) Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;
  return true;
}

export function validCNPJ(strCNPJ, blankValid = false) {
  if (!strCNPJ && blankValid) {
    return true;
  }

  strCNPJ = strCNPJ.replace(/[^\d]+/g, '');

  if (strCNPJ === '') return false;

  if (strCNPJ.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (strCNPJ === '00000000000000') return false;

  // Valida DVs
  let tamanho = strCNPJ.length - 2;
  let numeros = strCNPJ.substring(0, tamanho);
  const digitos = strCNPJ.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  let i;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0), 10)) {
    return false;
  }

  tamanho += 1;
  numeros = strCNPJ.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1), 10)) {
    return false;
  }

  return true;
}

export function padLeft(text, size) {
  if (text) {
    return (String('0').repeat(size) + text).substr(size * -1, size);
  }
  return '';
}

export function isValidArray(array) {
  return array && typeof array.map === 'function';
}

export function isScreenMobile() {
  return window.innerHeight < 480 || window.innerWidth < 640;
}

export function calcNaxItemsPage(qt768, qt900, qt1080, qtMobile) {
  const mobile = window.innerHeight < 480 || window.innerWidth < 640;

  if (mobile) {
    return qtMobile || 5;
  }

  let qtdRegs = qt768;
  if (screen.height >= 850 && screen.height <= 950) {
    qtdRegs = qt900;
  } else if (screen.height > 950) {
    qtdRegs = qt1080;
  }

  if (!isNumber(qtdRegs)) {
    return 11;
  }
  return qtdRegs;

  // let heightLine = 38.54;
  // if (screen.height >= 850 && screen.height <= 950) {
  //   heightLine = 36.25;
  // } else if (screen.height > 950) {
  //   heightLine = 35.0;
  // }

  // return Math.trunc((window.innerHeight / heightLine) * perc);
}

export function strNumBrToNumber(srtNumber, valDefault = undefined) {
  if (typeof strnumber === 'number') {
    return srtNumber;
  }
  if (!srtNumber || srtNumber.trim() === '') {
    return valDefault;
  }
  const sNumber = srtNumber.replace(/\./g, '').replace(/,/g, '.');
  if (isNumber(sNumber)) {
    return Number(sNumber);
  }
  return valDefault;
}
