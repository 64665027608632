import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import InputTextareaSp from '../../components/InputTextareaSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import { errorHandle, strToDate, validateFields } from '../../util/functions';
import { AuditoriaModel } from '../../util/Models';
import { StateScreen } from '../constants';
import AuditoriaService from '../../services/AuditoriaService';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';

function AuditoriaCrud(props) {
  AuditoriaCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  AuditoriaCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { stateScreen, idSelected, onClose } = props;

  // states
  const [auditoria, setAuditoria] = useState(new AuditoriaModel());
  // const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await AuditoriaService.findById(_id);
      retorno.dataRegistro = strToDate(retorno.dataRegistro);
      setAuditoria(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return true; // stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await AuditoriaService.insert(auditoria);
      } else {
        retorno = await AuditoriaService.update(auditoria);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new AuditoriaModel();

      setAuditoria(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <>
        <div className="p-col-8 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Data:</LabelSp>
          <CalendarSp
            required
            dateFormat="dd/mm/yy"
            readOnlyInput
            yearNavigator
            yearRange="1900:2030"
            locale={cfgPtBr}
            onChange={() => {}}
            value={auditoria.dataRegistro}
            disabled={viewMode()}
          />
        </div>

        <div className="p-col-4 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Hora</LabelSp>
          <InputTextSp
            value={auditoria.horaRegistro}
            maxLength={100}
            required
            disabled={viewMode()}
            // onChange={e => {
            //   setAuditoria({ ...auditoria, nome: e.target.value });
            // }}
          />
        </div>
        <div className="p-col-12 p-sm-5 p-lg-5 p-fluid">
          <LabelSp>Responsável</LabelSp>
          <InputTextSp
            value={auditoria.colaborador?.nome}
            maxLength={100}
            required
            disabled={viewMode()}
            // onChange={e => {
            //   setAuditoria({ ...auditoria, nome: e.target.value });
            // }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Ação</LabelSp>
          <InputTextSp value={auditoria.acao} maxLength={100} required disabled={viewMode()} />
        </div>

        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Ação</LabelSp>
          <InputTextareaSp
            resize={false}
            rows={5}
            value={auditoria.conteudo}
            maxLength={1000}
            required
            disabled={viewMode()}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              disabled={!validateFields(auditoria, ['nome'])}
              showConfirmation
              onClick={handleSave}
            />
          ) : null}
          <ButtonSp
            className="p-button-secondary"
            label="Voltar"
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    </>
  );
}

export default AuditoriaCrud;
