import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class FuncaoService extends BaseService {
  constructor() {
    super(`${ConfigApi.funcaoURL}`);
  }

  getFilter() {
    return new Filter();
  }

  async findGroup() {
    this.setLoading(true);
    try {
      const response = await this.api.get(`${this.url}?agrupado`);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.nome = '';
    this.limit = 11;
    this.page = 0;
  }
}
export default new FuncaoService();
