import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Container } from './styles';

import UsuarioService from '../../services/UsuarioService';
import AuthService from '../../services/AuthService';
import history from '../../services/history';
import {
  getPageParams,
  errorHandle,
  padLeft,
  calcNaxItemsPage,
  isScreenMobile,
} from '../../util/functions';
import LabelSp from '../../components/LabelSp';

import { showMessage } from '../../components/MessageDialog';
import InputTextSp from '../../components/InputTextSp';

import BotaoMenuGrid from '../../components/BotaoMenuGrid';

import UsuarioCrud from './crud';
import { StateScreen } from '../constants';
import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import DropDownLazy from '../../components/DropdownLazy';
import UsuarioPerfilService from '../../services/UsuarioPerfilService';
import EmpresaService from '../../services/EmpresaService';

export default function Usuario(props) {
  // useSelectors
  const filterVisible = useSelector(state => state.global.filterVisible);

  // useMemo
  const pageParams = useMemo(() => getPageParams(props.match.params, props.location.search), [
    props.location.search,
    props.match.params,
  ]);

  const filterService = useMemo(() => UsuarioService.getFilter(), []);

  // useStates
  const toBack = pageParams.toBack || '/usuarios';

  const [filter, setFilter] = useState(filterService);
  const [usuarios, setUsuarioes] = useState([]);

  const [usuarioPerfil, setUsuarioPerfil] = useState(null);
  const [empresa, setEmpresa] = useState(null);

  const [pageLimit, setPageLimit] = useState(filterService.limit);
  const [first, setFirst] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSelected, setPageSelected] = useState(0);
  const [funcTimeOut, setFuncTimeOut] = useState();

  // useCallbacks
  const loadUsuarioPerfil = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await UsuarioPerfilService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  // useCallbacks
  const loadEmpresa = useCallback(async _razaoSocial => {
    if (_razaoSocial !== undefined) {
      const r = await EmpresaService.findAll({ razaoSocial: _razaoSocial, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.razaoSocial,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const calcLimit = useCallback(() => {
    let ret;
    if (filterVisible) {
      ret = calcNaxItemsPage(10, 14, 19);
    } else {
      ret = calcNaxItemsPage(13, 17, 22);
    }
    setPageLimit(ret);
    return ret;
  }, [filterVisible]);

  const handleBuscar = useCallback(
    async (_filter, _page, resetPage = true) => {
      _filter.page = _page || 0;
      _filter.limit = calcLimit();
      try {
        const result = await UsuarioService.findAll(_filter);
        setUsuarioes(result.items);

        setTotalRecords(result.totalRecords);
        if (resetPage) {
          setFirst(0);
        }
      } catch (err) {
        errorHandle(err);
      }
    },
    [calcLimit]
  );

  const setFilterAndSearch = useCallback(
    async _filterValue => {
      if (JSON.stringify(_filterValue) !== JSON.stringify(filter)) {
        setFilter(_filterValue);

        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleBuscar(_filterValue);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [filter, funcTimeOut, handleBuscar]
  );

  function onPage(event) {
    const pagina = event.first / event.rows;
    setPageSelected(pagina);
    setFirst(event.first);
    handleBuscar(filter, pagina, false);
  }

  // useCallbacks
  const excluirRegistro = useCallback(
    async _id => {
      try {
        await UsuarioService.delete(_id);
        toast.success('Registro excluído com sucesso.');
        handleBuscar(filter);
      } catch (err) {
        errorHandle(err);
      }
    },
    [filter, handleBuscar]
  );

  // functions
  function getTitle() {
    const titleDefault = 'Usuario';
    let titleAdd = '';

    if (pageParams.stateScreen === StateScreen.stSearch) {
      titleAdd = '';
    }
    if (pageParams.stateScreen === StateScreen.stInsert) {
      titleAdd = '(Novo)';
    }
    if (pageParams.stateScreen === StateScreen.stUpdate) {
      titleAdd = `(Alterando Id: ${pageParams.idSelected})`;
    }
    if (pageParams.stateScreen === StateScreen.stView) {
      titleAdd = ` (Visualizando Id: ${pageParams.idSelected})`;
    }

    if (!isScreenMobile()) {
      return `${titleDefault} ${titleAdd}`;
    }

    return titleDefault;
  }

  const confirmaExclusao = useCallback(
    id => {
      showMessage('Confirmação', 'Confirma a exclusão do registro?', idx => {
        if (idx === 1) {
          excluirRegistro(id);
        }
      });
    },
    [excluirRegistro]
  );

  // useEffects
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      handleBuscar(filter, 0, true);
    }
    // desativado para evitar que a cada vez que o usuario digitasse o sistema buscasse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffects
  // Responsavel por recalcular a quantidade de registros da grid quando a div do filtro ocultar
  // ou ficar visivel
  useEffect(() => {
    if (pageParams.stateScreen === StateScreen.stSearch) {
      calcLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcLimit]);

  // renders
  return (
    <Container className="container-page">
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={!AuthService.checkRoles('USUARIO_INSERT')}
          showButtonFilter={pageParams.stateScreen === StateScreen.stSearch}
          showButtonInsert
          showButtonSearch
          titleFilterDefault="Buscar por Nome"
          handleFilterDefault={text => {
            filter.nome = text;
            setFilter({ ...filter, nome: text });
            handleBuscar(filter);
          }}
          handleClearFilters={() => {
            setFilter({
              ...filter,
              nome: '',
              email: '',
              idUsuarioPerfil: 0,
            });
            setUsuarioPerfil(null);
          }}
          handleButtonInsert={() => history.push('/usuarios/insert')}
          handleButtonSearch={() => handleBuscar(filter)}
        >
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>E-mail</LabelSp>
            <InputTextSp
              value={filter.email}
              maxLength={100}
              onChange={e => {
                setFilterAndSearch({ ...filter, email: e.target.value });
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Perfil</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todos"
              onChange={e => {
                setFilterAndSearch({ ...filter, idUsuarioPerfil: e?.value });
                setUsuarioPerfil(e);
              }}
              value={usuarioPerfil}
              onFilter={async txtFilter => {
                const retorno = await loadUsuarioPerfil(txtFilter);
                return retorno;
              }}
            />
          </div>
          <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
            <LabelSp>Empresa</LabelSp>
            <DropDownLazy
              autoLoad
              showClear
              placeholder="Todas"
              onChange={e => {
                setFilterAndSearch({ ...filter, idEmpresa: e?.value });
                setEmpresa(e);
              }}
              value={empresa}
              onFilter={async txtFilter => {
                const retorno = await loadEmpresa(txtFilter);
                return retorno;
              }}
            />
          </div>
        </CrudHeader>
        {pageParams.stateScreen === StateScreen.stSearch ? renderSearch() : renderCrud()}
      </div>
    </Container>
  );

  function renderSearch() {
    return (
      <>
        <div className="p-col-12 p-fluid datatable-doc-demo">
          <DataTableSp
            value={usuarios}
            style={{ marginBottom: '2px' }}
            paginator
            rows={pageLimit}
            lazy
            responsive
            totalRecords={totalRecords}
            first={first}
            onPage={onPage}
          >
            <Column
              field="id"
              body={rowData => padLeft(rowData.id, 6)}
              header="Id"
              className="grid-col-id"
            />
            <Column field="nome" className="grid-col" header="Nome" />
            <Column field="email" className="grid-col" header="E-mail" />
            <Column
              className="grid-col p-p-5"
              style={{ width: 200 }}
              header="Perfil"
              body={rowData => rowData.usuarioPerfilNome}
            />
            <Column
              className="gid-col-acoes-35"
              bodyStyle={{ textAlign: 'end' }}
              body={renderButtonOp}
            />
          </DataTableSp>
        </div>
      </>
    );
  }

  function renderButtonOp(rowData) {
    return (
      <BotaoMenuGrid
        handles={[
          () => history.push(`/usuarios/${rowData.id}?view`),
          () => history.push(`/usuarios/${rowData.id}`),
          () => confirmaExclusao(rowData.id),
        ]}
        disableds={[
          false,
          !AuthService.checkRoles('USUARIO_UPDATE'),
          !AuthService.checkRoles('USUARIO_DELETE'),
        ]}
      />
    );
  }

  function renderCrud() {
    return (
      <UsuarioCrud
        idSelected={pageParams.idSelected}
        stateScreen={pageParams.stateScreen}
        onClose={() => {
          history.push(toBack);
          handleBuscar(filter, pageSelected, false);
        }}
      />
    );
  }
}
