/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState, useCallback, useEffect } from 'react';

import { Column } from 'primereact/column';

import { toast } from 'react-toastify';

import { Container } from './styles';

import CotacaoService from '../../services/CotacaoService';

import { errorHandle, formatFloat, strToDate, arredondaValor } from '../../util/functions';

import CrudHeader from '../../components/CrudHeader';
import DataTableSp from '../../components/DataTableSp';
import InputCurrencySp from '../../components/InputCurrencySp';
import DropdownSp from '../../components/DropdownSp';
import LabelSp from '../../components/LabelSp';

import InputTextSp from '../../components/InputTextSp';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';
import ButtonSp from '../../components/ButtonSp';
import { showMessage } from '../../components/MessageDialog';

export default function CotacaoFornecedor() {
  // const [pageLimit] = useState(calcNaxItemsPage(8, 11, 16));
  // const [pageLimit] = useState());

  const [cotacao, setCotacao] = useState();
  const [cotacaoConcluida, setCotacaoConcluida] = useState(false);

  const [cotacaoDetalheGrid, setCotacaoDetalheGrid] = useState([]);

  const handleBuscar = useCallback(async () => {
    try {
      const result = await CotacaoService.findCotacaoFornecedor();

      let horaFim = `${result.horaFim}`;
      horaFim = horaFim.substring(0, 5);

      const dataHoraFim =
        strToDate(`${result.dataFim} ${horaFim}`, 'yyyy-MM-dd HH:mm') || new Date();

      if (dataHoraFim < new Date() && result.situacao !== 1) {
        result.situacao = 1;
      }

      if (result.horaInicio) {
        result.horaInicio =
          strToDate(`${result.dataInicio} ${result.horaInicio}`, 'yyyy-MM-dd HH:mm:ss') ||
          null;
      }

      result.dataInicio = strToDate(result.dataInicio);

      if (result.horaFim) {
        result.horaFim =
          strToDate(`${result.dataFim} ${result.horaFim}`, 'yyyy-MM-dd HH:mm:ss') || null;
      }

      result.dataFim = strToDate(result.dataFim);

      setCotacao(result);

      const cotacaoDet = result.cotacaoFornecedorDetalhe.map(e => {
        return { ...e, registroAlterado: false };
      });
      setCotacaoDetalheGrid(cotacaoDet);
    } catch (err) {
      errorHandle(err);
    }
  }, []);

  // functions
  function getTitle() {
    const titleDefault = 'Solicitação de Cotação';

    return titleDefault;
  }

  function onEditorValueChange(_props, value) {
    const updateDetalhe = [..._props.value];
    if (updateDetalhe[_props.rowIndex][_props.field] !== value) {
      updateDetalhe[_props.rowIndex].registroAlterado = true;
    }
    updateDetalhe[_props.rowIndex][_props.field] = value;

    if (_props.field === 'situacaoFornecedorDetalhe') {
      if (value !== 2) {
        updateDetalhe[_props.rowIndex].preco = 0;
      }
    }

    if (_props.field === 'quantidadeDisponivel') {
      if (value > updateDetalhe[_props.rowIndex].quantidade) {
        updateDetalhe[_props.rowIndex].quantidadeDisponivel =
          updateDetalhe[_props.rowIndex].quantidade;
      }

      if (!value) {
        updateDetalhe[_props.rowIndex].situacaoFornecedorDetalhe = 1;
      } else if (updateDetalhe[_props.rowIndex].preco > 0) {
        updateDetalhe[_props.rowIndex].situacaoFornecedorDetalhe = 2;
      } else {
        updateDetalhe[_props.rowIndex].situacaoFornecedorDetalhe = 0;
      }
    }

    if (_props.field === 'preco') {
      if (!value) {
        if (updateDetalhe[_props.rowIndex].quantidadeDisponivel > 0) {
          updateDetalhe[_props.rowIndex].situacaoFornecedorDetalhe = 0;
        } else {
          updateDetalhe[_props.rowIndex].situacaoFornecedorDetalhe = 1;
        }
      } else if (updateDetalhe[_props.rowIndex].quantidadeDisponivel > 0) {
        updateDetalhe[_props.rowIndex].situacaoFornecedorDetalhe = 2;
      }
    }

    let valorProdutos = 0;
    updateDetalhe.forEach(e => {
      valorProdutos += e.quantidadeDisponivel * e.preco;
    });

    valorProdutos = arredondaValor(valorProdutos);
    const { valorFrete, valorOutrasDespesas } = cotacao;
    setCotacao({
      ...cotacao,
      valorProdutos,
      valorFrete,
      valorOutrasDespesas,
      valorTotal: valorProdutos + valorFrete + valorOutrasDespesas,
    });

    setCotacaoDetalheGrid(updateDetalhe);
  }
  const hanbleValidaAndEncerra = () => {
    // validações
    if (cotacao.horaFim <= new Date()) {
      toast.error('Cotação perdeu a validade. Entre em contato com o solicitante da cotação', {
        autoClose: 3500,
      });
      return;
    }
    if (!cotacao.nomeResponsavel) {
      toast.error('Falta informar o responsável.', { autoClose: 3500 });
      return;
    }
    if (!cotacao.condicaoPagamento) {
      toast.error('Falta informar a condição de pagamento.', { autoClose: 3500 });
      return;
    }
    let existeItemAguardando = false;
    for (const detalhe of cotacaoDetalheGrid) {
      const situacao = detalhe.situacaoFornecedorDetalhe || 0;
      if (situacao === 0) {
        existeItemAguardando = true;
      }

      if (situacao === 2 && (!detalhe.preco || !detalhe.quantidadeDisponivel)) {
        toast.error(
          'Existem items com situação "Produto Disponível sem o preço e/ou quantidade.',
          { autoClose: 3500 }
        );
        return;
      }
    }

    const mensagem = existeItemAguardando
      ? 'Existem itens som situação "Aguardando Preço". Deseja encerrar a cotação assim mesmo?'
      : 'Deseja encerrar a cotaçaõ?';
    showMessage('Confirmação', mensagem, idx => {
      if (idx === 1) {
        handleEncerrar();
      }
    });
  };

  const handleAbrirCotacao = async () => {
    try {
      const cotacaoCompleta = { ...cotacao };
      delete cotacaoCompleta.cotacaoFornecedorDetalhe;
      await CotacaoService.abrirCotacaoFornecedor(cotacaoCompleta);
      toast.success('Registro salvo com sucesso.');

      // if (cotRetorno) {
      //   setCotacao({
      //     ...cotacao,
      //     situacao: cotRetorno.situacao,
      //     situacaoCotacaoFornecedor: cotRetorno.situacaoCotacaoFornecedor,
      //   });
      // }
      await handleBuscar();
      setCotacaoConcluida(false);
    } catch (err) {
      errorHandle(err);
    }
  };

  const handleEncerrar = async () => {
    try {
      const cotacaoDetalheAlterada = [];
      cotacaoDetalheGrid.forEach(e => {
        if (e.registroAlterado) {
          cotacaoDetalheAlterada.push(e);
        }
      });

      const cotacaoCompleta = { ...cotacao, cotacaoFornecedorDetalhe: cotacaoDetalheAlterada };

      const cotRetorno = await CotacaoService.encerraCotacaoFornecedor(cotacaoCompleta);
      toast.success('Registro salvo com sucesso.');
      setCotacaoConcluida(true);
      if (cotRetorno) {
        setCotacao({
          ...cotacao,
          situacao: cotRetorno.situacao,
          situacaoCotacaoFornecedor: cotRetorno.situacaoCotacaoFornecedor,
        });
      }
    } catch (err) {
      errorHandle(err);
    }
  };

  function viewMode() {
    return true;
  }
  function precoEditor(_props) {
    return (
      <InputCurrencySp
        value={_props.value[_props.rowIndex].preco || 0}
        onChange={(e, v) => onEditorValueChange(_props, v)}
      />
    );
  }

  function qtdEditor(_props) {
    return (
      <InputCurrencySp
        value={_props.value[_props.rowIndex].quantidadeDisponivel || 0}
        onChange={(e, v) => onEditorValueChange(_props, v)}
      />
    );
  }
  // useEffects
  useEffect(() => {
    handleBuscar();

    // desativado para evitar que a cada vez que o cotacao digitasse o sistema buscasse
  }, [handleBuscar]);

  // renders

  function situacaoEditor(_props) {
    const situacoes = [
      { label: 'Aguardando Preço', value: 0 },
      { label: 'Produto em Falta', value: 1 },
      { label: 'Produto disponível', value: 2 },
    ];

    return (
      <DropdownSp
        value={_props.value[_props.rowIndex].situacaoFornecedorDetalhe || 0}
        options={situacoes}
        onChange={e => onEditorValueChange(_props, e.value)}
        style={{ width: '100%' }}
        placeholder="Selecione"
      />
    );
  }

  const styleEnable =
    cotacao?.situacaoCotacaoFornecedor === 0
      ? {
          borderColor: '#3498db',
          borderStyle: 'solid',
          borderWidth: 2,
          borderRadius: 4,
        }
      : {};

  const renderFieldSituacao = rowData => {
    let texto = '';
    const styleStatus = { fontWeight: 'bold' };
    const situacao = rowData.situacaoFornecedorDetalhe || 0;
    if (situacao === 0) {
      texto = 'Aguardando Preço';
      styleStatus.color = '#db2b3c';
    }
    if (situacao === 1) {
      texto = 'Produto em Falta';
      styleStatus.color = '#ee6002';
    }
    if (situacao === 2) {
      texto = 'Produto disponível';
      styleStatus.color = '#007ad9';
    }
    return (
      <div
        style={{
          ...styleEnable,
          height: 30,
          display: 'flex',
          margin: -2,
          alignItems: 'center',
        }}
      >
        <span style={{ ...styleStatus, margin: 5 }}>{texto}</span>
      </div>
    );
  };
  // render principal
  return (
    <Container className="container-page">
      <div className="p-grid">
        <CrudHeader
          title={getTitle()}
          disabledButtonInsert={false}
          showButtonFilter={false}
          showButtonInsert={false}
          showButtonSearch
        />

        {renderCotacao()}
      </div>
    </Container>
  );

  function renderCotacao() {
    if (!cotacao) {
      return null;
    }
    return cotacaoConcluida
      ? renderCotacaoConcluida()
      : cotacao.situacao === 0
      ? renderSearch()
      : renderCotacaoEncerradaCancelada();
  }

  function renderCotacaoEncerradaCancelada() {
    return (
      <>
        {renderCabecalho()}
        <div className="p-col-12 p-fluid" style={{ paddingTop: 100, textAlign: 'center' }}>
          {cotacao && (
            <span style={{ fontSize: 30, color: '#db2b3c' }}>
              Desculpe, a cotação já foi {cotacao.situacao === 1 ? 'Encerrada' : 'Cancelada'}
            </span>
          )}
        </div>
      </>
    );
  }

  function renderCotacaoConcluida() {
    return (
      <>
        {renderCabecalho()}
        <div className="p-col-12 p-fluid" style={{ paddingTop: 100, textAlign: 'center' }}>
          <span style={{ fontSize: 30, color: '#007ad9' }}>
            Os dados foram enviados com sucesso!
          </span>
        </div>
      </>
    );
  }

  function renderCabecalho() {
    return (
      <>
        <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
          <LabelSp>Empresa Solicitante</LabelSp>
          <InputTextSp
            value={cotacao.nomeEmpresaGerencial}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setCotacao({ ...cotacao, nomeEmpresaGerencial: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-3 p-fluid">
          <LabelSp>Solicitante</LabelSp>
          <InputTextSp
            value={cotacao.nomeColaboradorGerencial}
            maxLength={100}
            required
            disabled={viewMode()}
            onChange={e => {
              setCotacao({ ...cotacao, nomeColaboradorGerencial: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Situação</LabelSp>
          <DropdownSp
            required
            value={cotacao.situacao}
            options={[
              { label: 'Aberta', value: 0 },
              { label: 'Encerrada', value: 1 },
              { label: 'Cancelada', value: 2 },
            ]}
            disabled={viewMode()}
            filterInputAutoFocus={false}
            onChange={e => {
              setCotacao({ ...cotacao, situacao: e.target.value });
            }}
          />
        </div>

        <div
          className="p-grid p-col-12 p-sm-3 p-lg-3 p-fluid"
          style={{ padding: 0, margin: 0 }}
        >
          <div className="p-col-7 p-fluid">
            <LabelSp>Data Fim</LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              dateFormat="dd/mm/yy"
              disabled={viewMode()}
              required
              locale={cfgPtBr}
              value={cotacao.dataFim}
              onChange={e => setCotacao({ ...cotacao, dataFim: e.value })}
            />
          </div>
          <div className="p-col-5 p-fluid">
            <LabelSp>Hora</LabelSp>
            <CalendarSp
              readOnlyInput
              appendTo={document.body}
              showTime
              timeOnly
              disabled={viewMode()}
              required
              locale={cfgPtBr}
              value={cotacao.horaFim}
              onChange={e => setCotacao({ ...cotacao, horaFim: e.value })}
            />
          </div>
        </div>
        <div className="p-col-12 p-sm-12 p-lg-12 p-fluid">
          <LabelSp>Dados do Fornecedor</LabelSp>
          <hr />
        </div>

        <div className="p-col-12 p-sm-7 p-lg-7 p-fluid">
          <LabelSp>Razão Social</LabelSp>
          <InputTextSp
            value={cotacao.fornecedorRazaoSocial}
            maxLength={100}
            required
            disabled={viewMode()}
            // onChange={e => {
            //   setCotacao({ ...cotacao, nomeEmpresaGerencial: e.target.value });
            // }}
          />
        </div>
        <div className="p-col-12 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Situação Fornecedor</LabelSp>
          <DropdownSp
            required
            value={cotacao.situacaoCotacaoFornecedor}
            options={[
              { label: 'Pendente', value: 0 },
              { label: 'Concluído', value: 1 },
            ]}
            disabled={viewMode()}
            filterInputAutoFocus={false}
            onChange={e => {
              setCotacao({ ...cotacao, situacaoCotacaoFornecedor: e.target.value });
            }}
          />
        </div>
        <div className="p-col-12 p-sm-2 p-lg-2" style={{ textAlign: 'start' }}>
          <LabelSp className="label-button">&nbsp;</LabelSp>
          {cotacao.situacaoCotacaoFornecedor === 1 ? (
            <ButtonSp
              className="p-button-secuntary"
              icon="pi pi-pencil"
              label="Abrir Cotação"
              showConfirmation
              onClick={handleAbrirCotacao}
            />
          ) : null}
        </div>
      </>
    );
  }

  function renderSearch() {
    return (
      <>
        {renderCabecalho()}

        <div className="p-col-12 p-fluid">
          <DataTableSp
            value={cotacaoDetalheGrid}
            style={{ marginBottom: '2px' }}
            paginator={false}
            // rows={pageLimit}
            responsive
            removableSort
            sortMode="multiple"
          >
            <Column
              className="grid-col p-p-5"
              header="GTIN"
              field="gtin"
              style={{ width: 120 }}
            />
            <Column className="grid-col " field="descricao" header="Descrição" sortable />
            <Column className="grid-col " field="unidade" header="Und" style={{ width: 60 }} />
            <Column
              className="grid-col-val"
              header="Qtd. Solicitada"
              style={{ width: 110 }}
              body={rowData => formatFloat(rowData.quantidade, 3)}
            />

            <Column
              className="grid-col"
              style={{ width: 180 }}
              header="Situação"
              field="situacaoFornecedorDetalhe"
              body={renderFieldSituacao}
              editor={cotacao.situacaoCotacaoFornecedor === 0 ? situacaoEditor : undefined}
            />

            <Column
              className="grid-col-val"
              field="quantidadeDisponivel"
              header="Qtd. Disponível"
              style={{ width: 110 }}
              // body={rowData => formatFloat(rowData.quantidadeDisponivel, 3)}

              body={rowData => {
                return (
                  <div
                    style={{
                      ...styleEnable,
                      height: 30,
                      display: 'flex',
                      margin: -2,
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <span style={{ margin: 5 }}>
                      {formatFloat(rowData.quantidadeDisponivel, 3)}
                    </span>
                  </div>
                );
              }}
              editor={cotacao.situacaoCotacaoFornecedor === 0 ? qtdEditor : undefined}
            />
            <Column
              field="preco"
              className="grid-col-val"
              header="Preço Unit."
              style={{ width: 100 }}
              // body={rowData => formatFloat(rowData.preco, 2)}

              body={rowData => {
                return (
                  <div
                    style={{
                      ...styleEnable,
                      height: 30,
                      display: 'flex',
                      margin: -2,
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    <span style={{ margin: 5 }}>{formatFloat(rowData.preco, 2)}</span>
                  </div>
                );
              }}
              editor={cotacao.situacaoCotacaoFornecedor === 0 ? precoEditor : undefined}
            />
          </DataTableSp>
        </div>
        <div className="p-col-12 p-sm-4 p-lg-3 p-fluid">
          <LabelSp>Responsável</LabelSp>
          <InputTextSp
            value={cotacao.nomeResponsavel}
            maxLength={50}
            required
            onChange={e => {
              setCotacao({ ...cotacao, nomeResponsavel: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-4 p-lg-3 p-fluid">
          <LabelSp>Condição Pagto</LabelSp>
          <InputTextSp
            title="Ex.: A Vista, 30 Dias(Boleto), 30/60/60 Dias(Cartão), 07/15 Dias, ..."
            value={cotacao.condicaoPagamento}
            maxLength={50}
            required
            onChange={e => {
              setCotacao({ ...cotacao, condicaoPagamento: e.target.value });
            }}
          />
        </div>

        <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
          <LabelSp>Prazo Ent(dias)</LabelSp>
          <InputCurrencySp
            digits={0}
            value={cotacao.prazoEntregaDias}
            onChange={(e, v) => setCotacao({ ...cotacao, prazoEntregaDias: v })}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Vr. Produtos</LabelSp>
          <InputCurrencySp
            value={cotacao.valorProdutos}
            disabled
            // onChange={(e, v) => setCotacao({ ...cotacao, valorProdutos: v })}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Vr. Frete</LabelSp>
          <InputCurrencySp
            value={cotacao.valorFrete}
            onChange={(e, v) => {
              const valorTotal = cotacao.valorProdutos + cotacao.valorOutrasDespesas + v;
              setCotacao({ ...cotacao, valorTotal, valorFrete: v });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Vr. Outras</LabelSp>
          <InputCurrencySp
            value={cotacao.valorOutrasDespesas}
            onChange={(e, v) => {
              const valorTotal = cotacao.valorProdutos + cotacao.valorFrete + v;
              setCotacao({ ...cotacao, valorTotal, valorOutrasDespesas: v });
            }}
          />
        </div>

        <div className="p-col-12 p-sm-3 p-lg-2 p-fluid">
          <LabelSp>Vr. Total</LabelSp>
          <InputCurrencySp
            disabled
            value={cotacao.valorTotal}
            onChange={(e, v) => setCotacao({ ...cotacao, valorTotal: v })}
          />
        </div>
        <div className="p-col-12 p-sm-2 p-lg-2" style={{ textAlign: 'start' }}>
          <LabelSp className="label-button">&nbsp;</LabelSp>
          {cotacao.situacaoCotacaoFornecedor === 0 ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Conncluír"
              onClick={hanbleValidaAndEncerra}
            />
          ) : null}
        </div>
      </>
    );
  }
}
