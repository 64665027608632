import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';
import api from './api';

class CotacaoService extends BaseService {
  constructor() {
    super(ConfigApi.cotacaoURL);
  }

  getFilter() {
    return new Filter();
  }

  async findCotacaoFornecedor() {
    this.setLoading(true);
    try {
      const response = await api.get('/cotacoesFornecedor');
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async updateCotacaoFornecedor(body) {
    this.setLoading(true);
    try {
      const response = await api.put(`/cotacoesFornecedor/${body.id}`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async encerraCotacaoFornecedor(body) {
    this.setLoading(true);
    try {
      const response = await api.put(`/encerraCotacoesFornecedor/${body.id}`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }

  async abrirCotacaoFornecedor(body) {
    this.setLoading(true);
    try {
      const response = await api.put(`/abrirCotacoesFornecedor/${body.id}`, body);
      return response.data;
    } finally {
      this.setLoading(false);
    }
  }
}

class Filter {
  constructor() {
    this.dataInicio = addMonths(new Date(), -1);
    this.dataFim = new Date();
    this.idEmpresa = undefined;
    this.situacao = '';
    this.numeroContrato = '';

    this.limit = 0;
    this.page = 0;
  }
}
export default new CotacaoService();
