import { addMonths } from 'date-fns';
import { BaseService } from './BaseService';
import { ConfigApi } from '../config/Constantes';

class AuditoriaService extends BaseService {
  constructor() {
    super(ConfigApi.auditoriaURL);
  }

  getFilter() {
    return new Filter();
  }
}

class Filter {
  constructor() {
    this.acao = '';
    this.conteudo = '';

    this.dataInicio = addMonths(new Date(), -1);
    this.dataFim = new Date();
    this.limit = 0;
    this.page = 0;
  }
}
export default new AuditoriaService();
