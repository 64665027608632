export class UsuarioModel {
  constructor() {
    this.id = undefined;
    this.idUsuarioPerfil = undefined;
    this.idEmpresa = undefined;
    this.nome = '';
    this.email = '';
    this.inativo = false;
    this.senha = '';
    this.empresa = undefined;
  }
}

export class FinanciadorModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class MunicipioModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.uf = '';
    this.codigoIbge = 0;
  }
}

export class UsuarioPerfilModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.usuarioPerfilFuncao = [];
  }
}

export class EmpresaModel {
  constructor() {
    this.id = undefined;
    this.numeroContrato = undefined;
    this.razaoSocial = '';
    this.tipoPessoa = 'J';
    this.cpfCnpj = '';
    this.inativo = false;
  }
}

export class AuditoriaModel {
  constructor() {
    this.id = undefined;
    this.idUsuario = null;
    this.dataRegistro = null;
    this.horaRegistro = null;
    this.acao = '';
    this.conteudo = '';
    this.usuario = new UsuarioModel();
  }
}

export class FuncaoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
    this.nomeAgrupamento = '';
    this.descricao = '';
  }
}

export class UsuarioPerfilFuncaoModel {
  constructor() {
    this.id = undefined;
    this.idUsuarioPerfil = null;
    this.idFuncao = null;
    this.ativo = false;
    this.funcao = new FuncaoModel();
  }
}

export class AtendimentoTipoModel {
  constructor() {
    this.id = undefined;
    this.nome = '';
  }
}

export class CotacaoCabecalhoModel {
  constructor() {
    this.id = undefined;
    this.idEmpresa = null;
    this.idColaboradorGerencial = null;
    this.idEmpresaGerencial = null;
    this.idCotacaoCabecalhoGerencial = null;
    this.numeroContrato = null;
    this.dataInicio = null;
    this.horaInicio = null;
    this.dataFim = null;
    this.horaFim = null;
    this.situacao = 0;
    this.observacao = '';
    this.nomeColaboradorGerencial = '';
    this.nomeEmpresaGerencial = '';
    this.dataHoraSincronizacao = null;
    this.cotacaoDetalhe = [];
    this.cotacaoFornecedor = [];
  }
}
