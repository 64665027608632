import AuthService from '../services/AuthService';
import history from '../services/history';

export default function createMenu() {
  const usuario = AuthService.getUsuario();
  const { idUsuarioPerfil } = usuario;

  const menuReturn = [
    {
      label: 'Home',
      title: 'Home',
      icon: 'pi pi-fw pi-home',
      to: '/home',
    },
  ];

  let menuCadastros = { items: [] };
  let menuLancamentos = { items: [] };
  // let menuRelatorios = { items: [] };
  let menuUtilitarios = { items: [] };

  if (idUsuarioPerfil === !1) {
    menuCadastros = null;
  } else {
    // menuRelatorios = {
    //   title: 'Relatórios',
    //   label: 'Relatórios',
    //   icon: 'pi pi-fw pi-folder-open',
    //   expanded: true,
    //   items: [],
    // };

    menuLancamentos = {
      title: 'Lançamentos',
      label: 'Lançamentos',
      icon: 'pi pi-fw pi-list',
      expanded: true,
      items: [],
    };

    menuCadastros = {
      title: 'Cadastros',
      label: 'Cadastros',
      icon: 'pi pi-fw pi-folder',
      expanded: true,
      items: [],
    };

    menuUtilitarios = {
      title: 'Utilitários',
      label: 'Utilitários',
      icon: 'pi pi-fw pi-cog',
      expanded: true,
      items: [],
    };

    // pi-th-large

    // menu cadastros
    if (AuthService.checkRoles(['USUARIO_VIEW', 'USUARIO_INSERT', 'USUARIO_UPDATE'])) {
      menuCadastros.items.push({
        title: 'Usuário',
        label: 'Usuário',
        icon: 'pi pi-users',
        command: () => history.push('/usuarios'),
      });
    }

    if (AuthService.checkRoles(['EMPRESA_VIEW', 'EMPRESA_INSERT', 'EMPRESA_UPDATE'])) {
      menuCadastros.items.push({
        title: 'Empresa',
        label: 'Empresa',
        icon: 'pi pi-book',
        command: () => history.push('/empresas'),
      });
    }

    if (
      AuthService.checkRoles([
        'USUARIO_PERFIL_VIEW',
        'USUARIO_PERFIL_INSERT',
        'USUARIO_PERFIL_UPDATE',
      ])
    ) {
      menuCadastros.items.push({
        title: 'Perfil de Acesso',
        label: 'Perfil de Acesso',
        icon: 'pi pi-key',
        command: () => history.push('/usuarioPerfis'),
      });
    }

    // menu Lançamentos
    menuLancamentos.items.push({
      title: 'Cotação',
      label: 'Cotação',
      icon: 'pi pi-book',
      command: () => history.push('/cotacoes'),
    });
  }

  if (menuCadastros && menuCadastros.items.length > 0) {
    menuReturn.push(menuCadastros);
  }

  if (menuLancamentos) {
    menuReturn.push(menuLancamentos);
  }

  // if (menuRelatorios) {
  //   menuReturn.push(menuRelatorios);
  // }

  if (menuUtilitarios && menuUtilitarios.items.length > 0) {
    menuReturn.push(menuUtilitarios);
  }

  // menuReturn.push({
  //   label: 'Configurações',
  //   icon: 'pi pi-fw pi-cog',
  //   to: '/configuracoes',
  // });

  return menuReturn;
}
