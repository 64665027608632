/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getElementArray } from '../../util/functions';

export default function DropDownLazy(props) {
  const {
    onFilter,
    timeout,
    showClear,
    onChange,
    filterPlaceholder,
    placeholder,
    value,
    className,
    required,
    disabled,
    autoLoad,
    //    filterAfterInit,
  } = props;

  DropDownLazy.propTypes = {
    onFilter: PropTypes.func.isRequired,
    timeout: PropTypes.number,
    showClear: PropTypes.bool,
    onChange: PropTypes.func,
    filterPlaceholder: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.object,
    className: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    autoLoad: PropTypes.bool,
    // filterAfterInit: PropTypes.bool,
  };

  const [classDivPrincipal, setClassDivPrincipal] = useState('p-dropdown p-component');
  const txtAutoComplet = useMemo(() => {
    return React.createRef();
  }, []);
  const refDivUlScroll = useMemo(() => {
    return React.createRef();
  }, []);

  const [styleVisible, setStyleVisible] = useState('hidden');

  const [textFilter, setTextFilter] = useState('');
  const [localOptions, setLocalOptions] = useState([]);
  const [filterTimeOut, setFilterTimeOut] = useState();

  const [localValue, setLocalValue] = useState(value);

  let classNames = '';

  if (disabled) {
    classNames += ' p-disabled ';
  } else {
    classNames = required && !disabled ? ` p-error ${className || ''}` : className || '';
  }

  useEffect(() => {
    setLocalValue(value);
    if (!value) {
      setTextFilter('');
      setLocalOptions([]);
    } else {
      // setTextFilter(value.label);
      // setTimeout(() => txtAutoComplet.current.select(), 100);
    }
  }, [value, txtAutoComplet]);

  async function doFilter(val, _autoLoad = false) {
    const op = await onFilter(val);
    let valueSet = null;
    if (_autoLoad && op && op.length > 0) {
      if (!value) {
        // eslint-disable-next-line prefer-destructuring
        // valueSet = op[0];
      } else {
        valueSet = value;
      }
    }

    if (op) {
      setValueAndChange(valueSet, _autoLoad);
      if (valueSet) {
        setTextFilter(valueSet.label);
      }
    }

    setLocalOptions(op);
  }

  function textFilterChange(e) {
    const val = e.target.value;
    setTextFilter(val);
    if (onFilter) {
      if (filterTimeOut) {
        clearTimeout(filterTimeOut);
      }
      const func = setTimeout(async () => {
        doFilter(val);
      }, timeout || 800);
      setFilterTimeOut(func);
    }
  }

  function setValueAndChange(_value, _setFilter = true) {
    setLocalValue(_value);

    if (_setFilter && _value?.label) {
      setTextFilter(_value.label);
      setTimeout(() => txtAutoComplet.current.select(), 100);
    } else if (_setFilter) {
      setTextFilter('');
    }
    if (onChange) {
      onChange(_value);
    }
  }

  function showPopup() {
    if (disabled) {
      return;
    }
    if (styleVisible === 'hidden') {
      setClassDivPrincipal('p-dropdown p-component p-focus');

      setStyleVisible('visible');
      setTimeout(() => {
        txtAutoComplet.current.focus(); // document.getElementById(idTextAutoComplet).focus();

        if (autoLoad && txtAutoComplet && txtAutoComplet.current) {
          if (
            localOptions &&
            localOptions.length === 0 &&
            txtAutoComplet.current.value === ''
          ) {
            doFilter(' ', true);
          }
        }
      }, 100);
    }
  }

  function handleKeyDownFilter(e) {
    if (e.keyCode === 13) {
      setStyleVisible('hidden');
      if (localValue) {
        setValueAndChange(localValue, true);
      }
    } else if (localOptions !== undefined && localOptions.length > 0) {
      // baixo
      if (e.keyCode === 40) {
        if (localValue === null) {
          setValueAndChange(localOptions[0], false);
        } else {
          const elem = getElementArray(localOptions, localValue, 1);
          if (elem) {
            setValueAndChange(elem, false);
          }
        }
        setTimeout(() => {
          scrollUL('_li_item_selected', true);
        }, 100);

        e.preventDefault();
      }
      if (e.keyCode === 38) {
        const elem = getElementArray(localOptions, localValue, -1);
        if (elem) {
          setValueAndChange(elem, false);
        }
        setTimeout(() => {
          scrollUL('_li_item_selected', false);
        }, 100);

        e.preventDefault();
      }
    }
  }

  function scrollUL(li) {
    if (typeof li !== 'object') {
      li = document.getElementById(li);
    }
    if (li) {
      const list = refDivUlScroll.current; // document.getElementById('_div_ul_scroll');
      if (list) {
        const fudgeBotton = -46;
        const fudgeTop = 46;
        const bottom = list.scrollTop + (list.offsetHeight - fudgeBotton) - li.offsetHeight;
        const top = list.scrollTop + fudgeTop;
        if (li.offsetTop < top) {
          list.scrollTop = li.offsetTop - fudgeTop;
        } else if (li.offsetTop >= bottom) {
          list.scrollTop = li.offsetTop - (list.offsetHeight - fudgeBotton - li.offsetHeight);
        } else if (li.offsetTop === top) {
          list.scrollTop = 0;
        }
      }
    }
  }

  return (
    <>
      <div
        className={classDivPrincipal + classNames}
        onFocus={() => setClassDivPrincipal('p-dropdown p-component p-focus')}
      >
        <div className="p-hidden-accessible">
          <input
            type="text"
            role="listbox"
            readOnly=""
            disabled={disabled}
            onFocus={() => setClassDivPrincipal('p-dropdown p-component p-focus')}
            onBlur={() => setClassDivPrincipal('p-dropdown p-component')}
            onKeyPress={e => {
              if (e.key === ' ') showPopup();
            }}
          />
        </div>
        <div className="p-hidden-accessible p-dropdown-hidden-select">
          <select required="" tabIndex="-1" aria-hidden="true" disabled={disabled}>
            <option value="">{placeholder || ''}</option>
          </select>
        </div>
        <label className="p-dropdown-label p-inputtext p-placeholder" onClick={showPopup}>
          {localValue ? localValue.label : placeholder || 'Select item'}
        </label>
        {showClear && localValue ? (
          <i
            className="p-dropdown-clear-icon pi pi-times"
            onClick={() => {
              if (!disabled) {
                setValueAndChange(null);
              }
            }}
          />
        ) : null}
        <div className="p-dropdown-trigger">
          <span
            className="p-dropdown-trigger-icon pi pi-chevron-down p-clickable"
            onClick={showPopup}
          />
        </div>
        <div
          className="p-dropdown-panel p-hidden p-input-overlay p-input-overlay-visible"
          style={{
            visibility: styleVisible,
            display: 'block',
            top: 33,
            left: 0,
            zIndex: 1003,
          }}
        >
          <div
            className="p-dropdown-filter-container"
            style={{
              width: '100%',
            }}
          >
            <input
              // id={idTextAutoComplet}
              ref={txtAutoComplet}
              autoComplete="off"
              className="p-dropdown-filter p-inputtext p-component"
              placeholder={filterPlaceholder || ''}
              onBlur={() => {
                setTimeout(() => {
                  setStyleVisible('hidden');
                  setClassDivPrincipal('p-dropdown p-component');
                }, 200);
              }}
              value={textFilter || ''}
              onChange={textFilterChange}
              onKeyDown={handleKeyDownFilter}
            />
          </div>
          <div
            ref={refDivUlScroll}
            className="p-dropdown-items-wrapper"
            style={{ maxHeight: 200 }}
          >
            <ul className="p-dropdown-items p-dropdown-list p-component">{renderItem()}</ul>
          </div>
        </div>
      </div>
    </>
  );

  function renderItem() {
    if (localOptions !== undefined) {
      const retorno = localOptions.map(e => {
        let idItemSelected = '';
        const itemSelecionado = e?.value === localValue?.value;
        if (itemSelecionado) {
          idItemSelected = '_li_item_selected';
        }
        return (
          <li
            id={idItemSelected}
            key={e.value}
            className={itemSelecionado ? 'p-dropdown-item p-highlight' : 'p-dropdown-item'}
            onClick={() => {
              setValueAndChange(e);
            }}
          >
            {e.label}
          </li>
        );
      });

      return retorno;
    }
    return null;
  }
}
