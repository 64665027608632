import { getApiInfo, checkVersaoApi as apiCheckVersaoApi } from './api';

class ApiInfoService {
  async apiInfo() {
    return getApiInfo();
  }

  async checkVersaoApi() {
    return apiCheckVersaoApi();
  }
}

export default new ApiInfoService();
