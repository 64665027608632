import React from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { store } from '../../../../store';
import { showDialogLogin } from '../../../../store/modules/dialog/actions';
import { showMessage } from '../../../../components/MessageDialog';
import { logout } from '../../../../store/modules/auth/actions';

export function Topbar(props) {
  Topbar.defaultProps = {
    onToggleMenu: null,
  };

  Topbar.propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
    toggleMenuVisible: PropTypes.bool,
  };

  Topbar.defaultProps = {
    toggleMenuVisible: true,
  };
  // const { tokenExpiring } = useSelector(state => {
  //   return state.global;
  // });

  const tokenExpiring = false; // desativado nesse sistema

  function handleSair() {
    showMessage('Confirmação', 'Deseja sair?', idx => {
      if (idx === 1) {
        store.dispatch(logout());
      }
    });
  }

  function handleLogin() {
    store.dispatch(showDialogLogin());
  }

  return (
    <>
      <div className="layout-topbar clearfix">
        {props.toggleMenuVisible ? (
          <button className="p-link layout-menu-button" onClick={props.onToggleMenu}>
            <span className="pi pi-bars" />
          </button>
        ) : (
          <div className="p-link layout-menu-button" style={{ height: 26 }} />
        )}

        <div className="layout-topbar-icons">
          {tokenExpiring ? (
            <button
              className="p-link"
              title="Conexão vence em menos de 5 mintos. Click para renovar conexão"
              onClick={() => handleLogin()}
            >
              <span className="layout-topbar-item-text">Usuário</span>
              <span className="layout-topbar-icon pi pi-user" />
              <span className="layout-topbar-badge blink-animated">!</span>
            </button>
          ) : null}
          {props.toggleMenuVisible ? (
            <button className="p-link" title="Sair" onClick={() => handleSair()}>
              <span className="layout-topbar-item-text">Settings</span>
              <span className="layout-topbar-icon pi pi-fw pi-power-off" />
            </button>
          ) : null}
        </div>

        <div className="layout-topbar-title">Speedy Cotação</div>
      </div>
    </>
  );
}
