import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;

  .group-box {
    border-style: solid;
    border: 1px solid #969595;
    border-radius: 4px;
  }
`;
