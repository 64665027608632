import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function PanelFilter(props) {
  PanelFilter.propTypes = {
    visible: PropTypes.string,
  };

  const { visible } = props;

  const filterVisible = useSelector(state => state.global.filterVisible);

  if (filterVisible || visible) {
    return (
      <div {...props} style={{ margin: '0px', padding: '0px' }}>
        {props.children}
      </div>
    );
  }
  return null;
}
