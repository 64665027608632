import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Column } from 'primereact/column';
import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';

import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';

import {
  errorHandle,
  formatFloat,
  padLeft,
  strToDate,
  validateFields,
} from '../../util/functions';
import { CotacaoCabecalhoModel } from '../../util/Models';
import { StateScreen } from '../constants';
import CotacaoService from '../../services/CotacaoService';
import DropdownSp from '../../components/DropdownSp';
import CurrencyInput from '../../components/InputCurrencySp';
import CalendarSp from '../../components/CalendarSp';
import { cfgPtBr } from '../../config/Constantes';
import DataTableSp from '../../components/DataTableSp';

function CotacaoCrud(props) {
  CotacaoCrud.propTypes = {
    // stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  CotacaoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
  };

  const { idSelected, onClose } = props;
  const stateScreen = StateScreen.stView;

  // states
  const [cotacao, setCotacao] = useState(new CotacaoCabecalhoModel());
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);

  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await CotacaoService.findById(_id);

      if (retorno.horaInicio) {
        retorno.horaInicio =
          strToDate(`${retorno.dataInicio} ${retorno.horaInicio}`, 'yyyy-MM-dd HH:mm:ss') ||
          null;
      }

      retorno.dataInicio = strToDate(retorno.dataInicio);

      if (retorno.horaFim) {
        retorno.horaFim =
          strToDate(`${retorno.dataFim} ${retorno.horaFim}`, 'yyyy-MM-dd HH:mm:ss') || null;
      }

      retorno.dataFim = strToDate(retorno.dataFim);

      setCotacao(retorno);
      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          onClose();
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      if (stateScreen === StateScreen.stInsert) {
        retorno = await CotacaoService.insert(cotacao);
      } else {
        retorno = await CotacaoService.update(cotacao);
      }
      toast.success('Registro salvo com sucesso.');
      onClose(retorno);
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new CotacaoCabecalhoModel();

      setCotacao(novo);
    }
  }, [loadRecord, idSelected, stateScreen]);

  // render principal
  return (
    <>
      <div className="p-col-6 p-sm-2 p-lg-2 p-fluid">
        <LabelSp>Nr Contrato</LabelSp>
        <CurrencyInput
          mask="999999"
          required
          digits={0}
          value={cotacao.numeroContrato}
          disabled={viewMode()}
          onChange={e => {
            setCotacao({ ...cotacao, numeroContrato: e.target.value });
          }}
        />
      </div>

      <div className="p-col-12 p-sm-6 p-lg-6 p-fluid">
        <LabelSp>Empresa</LabelSp>
        <InputTextSp
          value={cotacao.nomeEmpresaGerencial}
          maxLength={100}
          required
          disabled={viewMode()}
          onChange={e => {
            setCotacao({ ...cotacao, nomeEmpresaGerencial: e.target.value });
          }}
        />
      </div>

      <div className="p-col-12 p-sm-4 p-lg-4 p-fluid">
        <LabelSp>Responsável</LabelSp>
        <InputTextSp
          value={cotacao.nomeColaboradorGerencial}
          maxLength={100}
          required
          disabled={viewMode()}
          onChange={e => {
            setCotacao({ ...cotacao, nomeColaboradorGerencial: e.target.value });
          }}
        />
      </div>

      <div className="p-col-6 p-sm-4 p-lg-4 p-fluid">
        <LabelSp>Situação</LabelSp>
        <DropdownSp
          required
          value={cotacao.situacao}
          options={[
            { label: 'Aberta', value: 0 },
            { label: 'Encerrada', value: 1 },
            { label: 'Cancelada', value: 2 },
          ]}
          disabled={viewMode()}
          filterInputAutoFocus={false}
          onChange={e => {
            setCotacao({ ...cotacao, inativo: e.target.value });
          }}
        />
      </div>
      <div className="p-grid p-col-12 p-sm-4 p-lg-4 p-fluid" style={{ padding: 0, margin: 0 }}>
        <div className="p-col-7 p-fluid">
          <LabelSp>Data Inicio</LabelSp>
          <CalendarSp
            readOnlyInput
            appendTo={document.body}
            dateFormat="dd/mm/yy"
            disabled={viewMode()}
            required
            locale={cfgPtBr}
            value={cotacao.dataInicio}
            onChange={e => setCotacao({ ...cotacao, dataInicio: e.value })}
          />
        </div>
        <div className="p-col-5 p-fluid">
          <LabelSp>Hora</LabelSp>
          <CalendarSp
            readOnlyInput
            appendTo={document.body}
            showTime
            timeOnly
            disabled={viewMode()}
            required
            locale={cfgPtBr}
            value={cotacao.horaInicio}
            onChange={e => setCotacao({ ...cotacao, horaInicio: e.value })}
          />
        </div>
      </div>
      <div className="p-grid p-col-12 p-sm-4 p-lg-4 p-fluid" style={{ padding: 0, margin: 0 }}>
        <div className="p-col-7 p-fluid">
          <LabelSp>Data Fim</LabelSp>
          <CalendarSp
            readOnlyInput
            appendTo={document.body}
            dateFormat="dd/mm/yy"
            disabled={viewMode()}
            required
            locale={cfgPtBr}
            value={cotacao.dataFim}
            onChange={e => setCotacao({ ...cotacao, dataFim: e.value })}
          />
        </div>
        <div className="p-col-5 p-fluid">
          <LabelSp>Hora</LabelSp>
          <CalendarSp
            readOnlyInput
            appendTo={document.body}
            showTime
            timeOnly
            disabled={viewMode()}
            required
            locale={cfgPtBr}
            value={cotacao.horaFim}
            onChange={e => setCotacao({ ...cotacao, horaFim: e.value })}
          />
        </div>
      </div>

      <div className="p-col-12 p-fluid" style={{ marginTop: 5 }}>
        <hr />
        <LabelSp>Produtos</LabelSp>
      </div>

      <div className="p-col-12 p-fluid">
        <DataTableSp
          value={cotacao.cotacaoDetalhe}
          style={{ marginBottom: '2px' }}
          paginator
          responsive
          rows={6}
        >
          <Column
            className="grid-col-id"
            header="Id"
            body={rowData => padLeft(rowData.idProduto, 6)}
          />

          <Column field="gtin" className="grid-col" header="GTIN" style={{ width: 117 }} />
          <Column field="descricao" className="grid-col" header="Descrição" />
          <Column field="unidade" className="grid-col" header="UN." style={{ width: 60 }} />

          <Column
            className="grid-col-val"
            header="Quantidade"
            body={rowData => formatFloat(rowData.quantidade, 2)}
          />
        </DataTableSp>
      </div>

      <div className="p-col-12 p-fluid" style={{ marginTop: 5 }}>
        <hr />
        <LabelSp>Fornecedores</LabelSp>
      </div>

      <div className="p-col-12 p-fluid">
        <DataTableSp
          value={cotacao.cotacaoFornecedor}
          style={{ marginBottom: '2px' }}
          paginator
          responsive
          rows={6}
        >
          <Column field="fantasia" className="grid-col" header="Nome" />
          <Column
            field="cpfCnpj"
            className="grid-col"
            header="CPF/CNPJ"
            style={{ width: 150 }}
          />

          <Column
            className="grid-col"
            header="Situação"
            style={{ width: 150 }}
            body={rowData => (rowData.situacao === 0 ? 'Pendente' : 'Concluído')}
          />
        </DataTableSp>
      </div>

      <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
        {!viewMode() ? (
          <ButtonSp
            className="p-button-success"
            icon="pi pi-save"
            label="Salvar"
            disabled={!validateFields(cotacao, ['razaoSocial', 'cpfCnpj', 'numeroContrato'])}
            showConfirmation
            onClick={handleSave}
          />
        ) : null}
        <ButtonSp
          className="p-button-secondary"
          label="Voltar"
          icon="pi pi-chevron-circle-left"
          onClick={handleBack}
        />
      </div>
    </>
  );
}

export default CotacaoCrud;
