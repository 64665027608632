import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
`;

export const ContainerScrool = styled.div`
  .p-tree-container {
    height: 55vh !important;
    overflow: auto !important;
  }

  @media (min-width: 86em) {
    .p-tree-container {
      height: 65vh !important;
      overflow: auto !important;
    }
  }
`;
