import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import { Container, ContainerSearch } from './styles';
import { showFilter, hideFilter } from '../../store/modules/global/actions';
import PanelFilter from '../PanelFilter';

import InputTextSp from '../InputTextSp';

export default function CrudHeader(props) {
  CrudHeader.propTypes = {
    handleButtonInsert: PropTypes.func,
    showButtonInsert: PropTypes.bool,
    titleFilterDefault: PropTypes.string,
    handleFilterDefault: PropTypes.func,
    disabledButtonInsert: PropTypes.bool,
    handleClearFilters: PropTypes.func,
    showButtonFilter: PropTypes.bool,
    showButtonConfig: PropTypes.bool,
  };

  CrudHeader.defaultProps = {
    showButtonConfig: true,
  };

  const [filterDefault, setFilterDefault] = useState('');
  const [funcTimeOut, setFuncTimeOut] = useState();

  const dispatch = useDispatch();
  const filterVisible = useSelector(state => state.global.filterVisible);
  function handleFilterUpdate() {
    if (filterVisible) {
      dispatch(hideFilter());
    } else {
      dispatch(showFilter());
    }
  }

  const {
    showButtonInsert,
    handleButtonInsert,
    disabledButtonInsert,
    handleButtonSearch,
    titleFilterDefault,
    handleFilterDefault,
    handleClearFilters,
    showButtonFilter,
    handleButtonOptions,
    showButtonConfig,
  } = props;

  const autoSearch = useCallback(
    async _filter => {
      if (handleFilterDefault) {
        if (funcTimeOut) {
          clearTimeout(funcTimeOut);
        }
        const func = setTimeout(async () => {
          handleFilterDefault(_filter);
        }, 800);
        setFuncTimeOut(func);
      }
    },
    [funcTimeOut, handleFilterDefault]
  );
  return (
    <>
      <Container className="p-col-12">
        <span className="title">{props.title}</span>
        <hr />
      </Container>
      {showButtonFilter && (
        <>
          <div className="p-col-12">
            {showButtonInsert && (
              <Button
                className="buttons"
                title="Inserir"
                label="Inserir"
                icon="pi pi-plus-circle"
                type="button"
                onClick={() => {
                  if (handleButtonInsert) {
                    handleButtonInsert();
                  }
                }}
                disabled={disabledButtonInsert}
              />
            )}

            {handleButtonOptions && (
              <Button
                className="p-button-secondary buttons"
                title="Opções"
                label="Opções"
                icon="pi pi-list"
                type="button"
                onClick={() => {
                  if (handleButtonOptions) {
                    handleButtonOptions();
                  }
                }}
              />
            )}
            {showButtonConfig && (
              <Button
                style={{ float: 'right' }}
                className="p-button-success buttons"
                title="Filtros Adicionais"
                icon="pi pi-filter"
                onClick={handleFilterUpdate}
              />
            )}
            <ContainerSearch
              className="p-inputgroup"
              style={{ float: 'right', marginRight: 5 }}
            >
              {titleFilterDefault && (
                <InputTextSp
                  className="search"
                  placeholder={titleFilterDefault}
                  maxLength={40}
                  value={filterDefault}
                  onChange={e => {
                    setFilterDefault(e.target.value);
                    autoSearch(e.target.value);
                  }}
                  // onKeyDown={e => {
                  //   if (e.keyCode === 13) {
                  //     if (handleFilterDefault) {
                  //       handleFilterDefault(filterDefault);
                  //     }
                  //   }
                  // }}
                />
              )}

              <Button
                className="p-button-secondary buttons"
                title="Buscar"
                icon="pi pi-search"
                type="button"
                onClick={() => {
                  if (handleFilterDefault) {
                    handleFilterDefault(filterDefault);
                  }
                }}
              />
            </ContainerSearch>
          </div>

          {filterVisible && showButtonConfig && (
            <>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  handleButtonSearch();
                }}
              >
                <PanelFilter className="p-grid p-col-12">{props.children}</PanelFilter>
                <div className="p-col-12 p-lg-12">
                  <Button
                    className="p-button-secondary"
                    title="Buscar"
                    icon="pi pi-search"
                    // Deixei no submit do form o evento de buscar
                    // onClick={() => {
                    //   if (handleButtonSearch) {
                    //     handleButtonSearch();
                    //   }
                    // }}
                    label="Buscar"
                  />
                  <Button
                    className="p-button-warning"
                    title="Limpar Filtros"
                    icon="pi pi-times-circle"
                    onClick={() => {
                      if (handleClearFilters) {
                        setFilterDefault('');
                        handleClearFilters();
                      }
                    }}
                    label="Limpar"
                  />
                </div>
              </form>
            </>
          )}
        </>
      )}
    </>
  );
}
